.panelBox {
    position: absolute;
    background: rgba(0,0,0,.7);
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    z-index: 10000;
}
.panelSettingsControlPanel {
    width: 640px;
    min-height: 300px;
    background: #Fff;
    margin: auto;
    border-radius: 3px;
    margin-top: 31px;

}
.panelSettingsControlPanel * {
    box-sizing: border-box;
    /* font-family: 'Open Sans', sans-serif; */
}
.panelHeaders {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid rgb(229,229,229);
    position: relative;
}
.closePanel {
    font-size: 34px !important;
    position: absolute;
    top: 12px;
    right: 25px;
    color: rgb(213,214,215);
    cursor: pointer;
}
.panelHeaders > h2 {
    display: inline-block;
    margin-top: 23px;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 27px;
    font-weight: 100;
}
.tabsPanel {
    width: 100%;
    height: 80px;
    padding: 20px;
    box-sizing: border-box;
}
.itemTabsElem {
    height: 40px;
    width: calc(100% / 3);
    display: inline-block;
    float: left;
    text-align: center;
    /*padding-top: 10px;*/
    border-radius: 3px;
    box-sizing: border-box;
}
.itemTabsElem:nth-child(3) {
    border-bottom: 1px solid rgb(221,221,221);
    border-radius: 0px;
}
.activeTabs {
    border-top: 1px solid rgb(221,221,221);
    border-left: 1px solid rgb(221,221,221);
    border-right: 1px solid rgb(221,221,221);
    border-radius: 3px 3px 0px 0px;
}
.notActiveTabs {
    border-bottom: 1px solid rgb(221,221,221);
    border-radius: 0px 0px 0px 0px;
    color: rgb(189,189,191);
    cursor: pointer;
}
.itemTabsElem > span {
    width: 100%;
    height: 100%;
    display: inline-block;
    padding-top: 10px;
    transition: color .3s;
}
.activeTabs > span {
    padding-top: 9px;
}
 .activeTabs {
    color: rgb(69, 185, 118);
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalVirginStyle > div .activeTabs {
    color: rgb(255, 92, 92);
}
.boxParams {
    width: 100%;
    min-height: 188px;
    max-height: calc(100% - 240px);
    position: relative;
    overflow: auto;
    border-top: 1px solid rgb(221,221,221);
}
.authorizationBox {

    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transition: opacity .3s, height 1s;

}
.authorizationBox .paramsItem{
    min-height: 52px;
    background: rgb(246,246,246);
    margin-bottom: 8px;
    transition: background .3s;
}
.performanceBox {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    transition: opacity .3s, height 1s;
    /*padding-top: 20px;*/
}
.performanceBox > span {
    display: inline-block;
    width: 100%;
    height: 52px;
    padding-top: 16px;
}
.panelSettingsControlPanel > .footer {
    width: 640px;
    height: 71px;
    border-top: 1px solid rgb(229,229,229);
    padding: 20px;
    position: relative;
    box-sizing: border-box;
}
.panelSettingsControlPanel > .footer  button{
    height: 30px;
    border: 0px;
    border-radius: 2px;
    padding: 0px 10px;
}
.panelSettingsControlPanel > .footer  button.review{
    background: rgb(140,193,82);
    color: #fff;
    /*margin-right: 151px;*/
}
.panelSettingsControlPanel > .footer  button.closeButton{
    background: rgb(250,250,250);
    border: 1px solid rgb(224,224,224);
    margin-right: 8px;
    margin-left: 0px;
    float: right;
}
.panelSettingsControlPanel > .footer  button.save{
    color: #fff;
    float: right;
}
.panelSettingsControlPanel > .footer  button.save.disabledButton {
    opacity: .5;
    cursor: default;
}
.footer  button.save {
    background: rgb(69, 185, 118);
    position: relative;
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalVirginStyle > div .panelSettingsControlPanel > .footer  button.save {
    background: rgb(255, 92, 92);
    position: relative;
}
.panelSettingsControlPanel > .footer  button.desabledButton{
    cursor: default;
    opacity: .4;
}
.activeBox {
    opacity: 1;
    pointer-events: auto;
    /*display: block;*/
}
.notActiveBox {
    opacity: 0;
    pointer-events: none;
    /*display: none;*/
    height: 0px;
    overflow: hidden;
}
.paramsItem > div {
    /*height: 52px;*/
    display: inline-block;
    padding-top: 15px;
    float: left;
}
.paramsItem > div:nth-child(1) {
    width: calc(184px + 358px);
    padding-left: 20px;
}
.paramsItem > div:nth-child(2) {
    width: 0px;
}
.paramsItem > div:nth-child(3) {
    width: 12%;
    padding-left: 17px;
    box-sizing: border-box;

}
.paramsItem.paramsInput > div:nth-child(1) {
    width: calc(184px + 236px);
    padding-left: 20px;
}
.paramsItem.paramsInput > div:nth-child(2) {
    width: 0px;
}
.paramsItem.paramsInput > div:nth-child(3) {
    width: 220px;
    padding-left: 32px;

}
.paramsItem.paramsInput > div:nth-child(3) > input {

    width: 167px;
}
.authorizationBox .paramsItem.paramsInput {
    background: #fff;
}
.authorizationBox .paramsItem.paramsInput:hover {
    background: #fff;
}
.paramsItem > div:nth-child(1) > span {
    text-align: left;
    display: inline-block;
    margin-left: 10px;
    margin-top: 6px;
}
.paramsItem > div:nth-child(2) > span {
    text-align: right;
    display: inline-block;
    width: 100%;
    color: rgb(184,184,184);
    transition: color .3s;
}
/*.paramsItem > div:nth-child(3) {*/
    /*width: 98px;*/
/*}*/
.authorizationBox .paramsItem:hover{
    background: rgb(240,240,240);
}
.authorizationBox .paramsItem:hover > div:nth-child(2) > span {
    color: rgb(161,161,161);
}
.paramsItem > div.passInput {
    padding: 0px;
    padding-left: 20px;
    width: 174px;
    color: rgb(133,133,133);
}
.paramsItem > div.passInput > input {

}
.paramsItem > div.passInput > span {

}
.noSettingsAuthBox {
    padding: 20px;
}
.boxSpinerLoader {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 20px;
    right: 86px;
    text-align: center;
    font-size: 17px;
}
.boxSpinerLoader > i {

}
.authorizationBox div.paramsItem.paramsInput.inlineElem {
    height: 150px;
}
.authorizationBox div.paramsItem.paramsInput.inlineElem > div{
    width: 186px;
    height: 66px;
    margin: 20px;
    padding: 0px;
    margin-bottom: 0px;
    display: inline-block;
    margin-right: 0px;
}
.authorizationBox div.paramsItem.paramsInput.inlineElem > div > div{
    color: rgb(149,149,149) !important;
    font-size: 13px!important;
}
.authorizationBox div.paramsItem.paramsInput.inlineElem > div > div > input {
    width: 100%;
    border: 1px solid rgb(238, 238, 238);
    color: rgb(149,149,149) !important;
}
.paramsItem > div:nth-child(1) > div.iconBox {
    float: left;
    margin-top: -3px;
    margin-right: 10px;
    text-align: center;
    width: 27px;
    color: #455a64;
}

.paramsItem > div:nth-child(1) > div > i {

}
div.boxAutoPost {
    width: 100%;
    padding-left: 20px;
    height: inherit;
    padding-bottom: 20px;
    color: rgb(133,133,133);
}
div.boxAutoPost > div {
    height: 100%;
    /*display: inline-block;*/
    display: inline-table;
}
div.boxAutoPost > div:nth-child(1) {
    /*width: 110px;*/
}
div.boxAutoPost > div:nth-child(1) {
    /*width: 204px;*/
    margin-right: 10px;
}
div.boxAutoPost > div:nth-child(3) {
    width: 192px;
}
div.boxAutoPost > div > span{
    margin-right: 10px;
}
div.boxAutoPost > div:nth-child(1) > div >div {
    margin-top: 10px;
}
div.boxAutoPost > div:nth-child(1) > div > div > span{
    display: inline-block;
}

div.boxAutoPost > div:nth-child(1) > div > div > textarea{
    width: 100%;
}
div.boxAutoPost > div:nth-child(1) > div {
    /*margin-top: 10px;*/
    width: 178px;
    display: inline-table;
    margin-right: 10px;
}
div.boxAutoPost > div:nth-child(1) > div > label{
    display: inline-block;
    margin-right: 10px;
    width: min-content;
}
div.boxAutoPost > div:nth-child(1) > div > input:nth-child(3){
    width: 100%;
}
div.boxAutoPost > div:nth-child(1) > div > textarea{
    width: 100%;
}
.boxSelectAutoPost {
    display: inline-block;
    margin-left: 20px;
}
.boxSelectAutoPost > label {
    margin-right: 10px;
    font-size: 13px;
}
.boxSelectAutoPost > input[type='checkbox'] {
    margin-right: 10px;
}
.backgroundModalBoxImage {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0,0,0,.7);
    z-index: 10;
}
.modalBoxImage {
    width: 640px;
    height: calc(100% - 62px);
    background: #fff;
    margin: auto;
    margin-top: 31px;
    border-radius: 3px;
    position: relative;
}
.modalBoxImage > .footer {
    width: 640px;
    height: 71px;
    border-top: 1px solid rgb(229,229,229);
    padding: 20px;
    position: relative;
}
.modalBoxImage > .boxParams {
    border:0px;
    overflow: auto;
    max-height: calc(100% - 151px);
}
.itemImageBox {
    width: 116px;
    height: 116px;
    display: inline-block;
    float: left;
    margin: 2px;
    overflow: hidden;
    border: 1px solid rgb(229,229,229);
    background: rgb(245,245,245);
    position: relative;
    text-align: center;
}
.itemImageBox > img {
    max-width: 100%;
    max-height: 100%;
}
div.boxComponentImage {
    padding: 5px 20px;
}
.itemImageBox:hover .boxInfoImageHover {
    opacity: 1;
}
.boxInfoImageHover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-top: 45px;
    padding-left: 15px;
    opacity: 0;
    transition: opacity .3s;
}
.boxInfoImageHover > button {
    border-radius: 2px;
    border: 1px solid rgb(229,229,229);
    background: rgba(0,0,0,.7);
    color: rgb(229,299,229);
    display: block;
}
.modalBoxImage .footer{
    padding: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.modalBoxImage .footer > button {
    width: 100%;
    height: 100%;
    border-radius-top: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: none;
    background: rgb(189,189,189);
    color: #fff;
}
.boxImages {
    width: 116px;
    height: 116px;
    position: relative;
    background: rgb(229,229,229);
}
.boxImages > img{
    width: 100%;
}
.boxImages > span {

}
.addImage {
    /*float: left;*/
    width: 116px;
    height: 116px;
    position: relative;
    padding-top: 38px;
    background: rgb(229,229,229);
    border: none;
}
.addImage > i {
    position: absolute;
    top: 26px;
    left: 46px;
}
.deleteImage {
    position: absolute;
    top: 0px;
    right: 0px;
    color: red;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    width: 19px;
    text-align: center;
}
.boxImages:hover .deleteImage {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
}
.uploadPanel {
    width: 640px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 5px 16px rgba(0,0,0,.5);
    margin: auto;
    margin-top: 31px;
}
.uploadPanel * {
    box-sizing: border-box;
}
.uploadPanel > .boxParams > div {
    height: 52px;
    padding: 0px 20px;
    padding-top: 15px;
}
.uploadPanel > .boxParams {
    border: 0px;
}
.uploadPanel > .boxParams > div > input {
    width: 100%;
}
.uploadPanel > .footer {
    position: relative;
    height: 71px;
    padding: 20px;
    width: 640px;
    border-top: 1px solid rgb(229,229,229);
}
.uploadPanel > .footer > button {
    width: initial;
    height: 30px;
    border-radius: 2px;
}
.uploadPanel > .footer > button:nth-child(1) {
    margin-left: 450px;
}

.uploadPanel > .footer > button:nth-child(2) {
    margin-left: 5px;
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalShopsterStyle > div .uploadPanel > .footer > button:nth-child(2) {
    background: rgb(69, 185, 118);
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalVirginStyle > div .uploadPanel > .footer > button:nth-child(2) {
    background: rgb(255, 92, 92);
}
.uploadPanel > .footer > button:nth-child(2).NotActiveButtton {
    opacity: .5;
    cursor: default;
}
.uploadPanel > .footer > button:nth-child(2).ActiveButtton {
    opacity: 1;
}
div.spinerLoaderFiles {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 22px 307px;
    background: rgba(255,255,255,.7);
}
.spinerLoaderUploadFiles {
    position: absolute;
    top: 20px;
    right: 21px;
    width: 145px;
    height: 30px;
    padding: 5px 62px;
    background: rgba(255,255,255,.7);
}
.itemSetingsPerform {
    width: 100%;
    min-height: 85px;
    border-bottom: 1px dashed rgb(238,238,238);
    display: inline-block;
    padding: 20px;
}
.itemSetingsPerform > div{
    height: 100%;
    display: inline-block;
    float: left;
    position: relative;
}
.itemSetingsPerform > div:nth-child(1){
    width: 183px;
    text-align: right;
    /* padding-top: 18px; */
    /* padding-left: 20px; */
}
.itemSetingsPerform > div:nth-child(2){
    /*width: 435px;*/
    padding-left: 20px;
}
.itemSetingsPerform > div:nth-child(2) > *{
    float: left;
}
.itemSetingsPerform > div:nth-child(1) > span{

}
.buttonShowColorPicker {
    width: 50px;
    height: 50px;
    /*margin-top: 17px;*/
    border: rgb(230,230,230);
    background: url(../img/alpha.jpg) no-repeat center;
    cursor: pointer;
    display: inline-block;
    float: left;
    position: relative;
    box-shadow: 0px 0px 2px rgba(0,0,0,.3);
}
.buttonShowColorPicker:hover .deleteImage {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
}
.boxColorPicker {
    display: inline-block;
    position: fixed;
    top: 185px;
    left: 635px;
    z-index: 1;
}
.itemSetingsPerform > div:nth-child(2) > input {
    float: left;
    margin-top: 8px;
}
.itemSetingsPerform > div > button {
    float: left;
    width: 116px;
    height: 116px;
    position: relative;
    padding-top: 38px;
    background: rgb(229,229,229);
    border: none;
}
.itemSetingsPerform > div:nth-child(2) > span {
    float: left;
    width: 116px;
    height: 116px;
    position: relative;
    padding-top: 46px;
}
.itemSetingsPerform > div > button > i {
    position: absolute;
    top: 26px;
    left: 46px;
}
.boxImage {
    width: 116px;
    height: 116px;
    position: relative;
    background: rgb(229,229,229);
    overflow: hidden;
}
.boxImage > img {
    max-width: 100%;
    /* object-fit: contain; */
    /* object-fit: cover; */
    /* width: 50px; */
    /* height: 100px; */
    vertical-align: middle;
    display: inline-block;
    max-height: 100%;
}
.boxImage > .firstElementInInline {
    display: inline-block;
    height: 100%;
    width: 0px;
    vertical-align: middle;
}
.boxImage:hover .deleteImage {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
}
.itemImageBox > span {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    text-align: center;
    height: 22px;
    color: red;
    background: rgba(0,0,0,.4);
    opacity: 0;
    transition: opacity .3s;
    cursor: pointer;
}
.itemImageBox:hover > span {
    opacity: 1;
}
.boxSpinerLoaderTemPage {
    position: absolute;
    top: 20px;
    background: rgba(255,255,255,.8);
    width: 203px;
    height: 31px;
    text-align: left;
    padding-top: 6px;
    font-size: 12px !important;
}
.itemSetingsPerform > div:nth-child(2) > textarea {
    padding: 6px 10px;
    border: 1px solid #e0e0e0;
    border-top: 1px solid #dbdbdb;
    border-radius: 2px;
    background: #fafafa;
    -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, .01) !important;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, .01) !important;
    color: rgb(69, 90, 100);
}
.buttonInAuth {
    /*position: absolute;*/
    /* width: 162px; */
    right: 17px;
    float: right;
    background: rgb(140,193,82);
    color: #fff;
}
.fullTop {
    top:0px!important;
}
.notSave {
    position: absolute;
    top: 0;
    right: 0;
    width: 29%;
    height: 100%;
    background: rgba(255,255,255,.5);
    z-index: 10;
}
.panelSettingsControlPanel button {
    font-size: 13px !important;
}
.panelSettingsControlPanel span ,.panelSettingsControlPanel a{
    font-size: 13px;
}
.panelSettingsControlPanel a {
    color: #007bff !important;
    text-decoration: none;
    background-color: transparent !important;
    -webkit-text-decoration-skip: objects !important;
    cursor: pointer !important;
}

.rc-switch {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 44px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    vertical-align: middle;
    border-radius: 20px 20px;
    border: 1px solid #ccc;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 24px;
    top: 0;
}
.rc-switch:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: #fff;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: 0.3s;
    animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
    transform: scale(1.1);
    animation-name: rcSwitchOn;
}
.rc-switch:focus {
    box-shadow: 0 0 0 2px #d5f1fd;
    outline: none;
}
.rc-switch-checked {
    border: 1px solid #87d068;
    background-color: #87d068;
}
.rc-switch-checked .rc-switch-inner {
    left: 6px;
}
.rc-switch-checked:after {
    left: 22px;
}
.rc-switch-disabled {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;
}
.rc-switch-disabled:after {
    background: #9e9e9e;
    animation-name: none;
    cursor: no-drop;
}
.rc-switch-disabled:hover:after {
    transform: scale(1);
    animation-name: none;
}
.rc-switch-label {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
@keyframes rcSwitchOn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1.1);
    }
}
@keyframes rcSwitchOff {
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
