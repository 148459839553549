.boxReactSelect {
  height: 23px;
  width: 218px;
  //margin-bottom: 10px;
  position: relative;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  overflow: hidden;
  select {
    font-size: 13px;
    height: 23px;
    width: 268px;
    color: #000;
    background: transparent;
    border: none;
    //font-size: 14px;
    //height: 29px;
    padding: 0 5px;
    //width: 268px;
    outline: none;
  }
}