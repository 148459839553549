.BoxInputParameters {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  // height: 100px;
  // background-color: red;
}

.BoxInputParameters__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.BoxInputParameters__title {
  font-family: ProximaNova;
  font-size: 17px;
  color: #a0a7b2;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(203, 199, 197);
}

.BoxInputParameters__drop-button {
  outline: none !important;
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0.0);
}
