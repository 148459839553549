$colorButtonHotspot: rgba(0,0,0,.0);
$dividerHeight: 4px;
$paddingTopButton: 20px;
$backgroundDivider: #f6f6f6;
$colorFontButton:#f1f8f3;

.buttonsHotspot {
  height: 100%;
  width: auto;
  // float: left;
  display: flex;
  padding-left: 21px;
  div.boxButton {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 21px;
    // float: left;
    div.buttonHotdpot {
      position: relative;
      color: $colorFontButton;
      font-family: ProximaNova;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      // height: 73%;
      //font-weight: 100;
      // display: inline-block;
      // padding-top: $paddingTopButton;
      // padding-left: 21px;
      // padding-right: 21px;
      //letter-spacing: -1px;
      div.divider {
        position: absolute;
        width: 100%;
        height: $dividerHeight;
        bottom: -7px;
        left: 0;
        // margin-top: 3px;
      }
      div.divider.active {
        width: 100%;
        height: $dividerHeight;
        background: $backgroundDivider;
        margin-top: 3px;
      }
    }
  }
}
