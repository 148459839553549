$widthLeftPanel: 238px;
$backgroundLeftPanel: #fff;
$topLeftPanel: 50px;
// $heightLeftPanel: 100%;
$heightLeftPanel: calc(100% - 50px);
$BottomLeftPanel: 0px;


.leftPanel {
  width: $widthLeftPanel;
  height: calc(100vh - 50px);
  flex-grow: 3;
  border-right: 1px solid rgba(100, 100, 100, 0.2);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  //position: fixed;
  //top: $topLeftPanel;
  //bottom: $BottomLeftPanel;
}
.leftPanelBox {
  position: absolute;
  width: $widthLeftPanel;
  height: $heightLeftPanel;
  top: $topLeftPanel;
  bottom: $BottomLeftPanel;
  z-index: 201;

}
