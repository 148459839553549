@import "../../../../variables.scss";

$widthPanelReporting: 243px;

.boxReportingObjects_overlay {
  position: fixed;
  overflow: auto;
  left: 238px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: $overlayColor;
  z-index: 100;
  .leftBackground {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 238px;

  }
  .rightBackground {
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    left: 482px;
  }
}

.boxReportingObjects {
  width: $widthPanelReporting;
  height: 100%;
  background: #fff;
  position: fixed;
  overflow: auto;
  left: 238px;
  padding: 10px;
  top: 0px;
  border-right: 1px solid rgba(100, 100, 100, 0.2);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  z-index: 198;
  .settingsBoxReportings {
    width: 100%;
    height: 50px;
    //background: #8c9790;
    button {
      float: right;
      background: rgba(0, 0, 0, 0.0);
      border: none;
      outline: 0px;
      margin-top: 12px;
      margin-right: 12px;
      border-radius: 2px;
      cursor: pointer;
    }
    h4 {
      font-family: 'ProximaNova';
      font-weight: 100;
      margin: 14px 15px;
      display: inline-block;
      font-size: 18px;
      color: rgb(35,49,56);
    }
  }
  .reportingPanelBox {
    width: 100%;
    height: initial;
    border-top: 1px solid rgba(0,0,0,.2);
    position: relative;
    h3 {
      font-family: ProximaNova;
      display: inline-block;
      margin-left: 10px;
      margin-top: 12px;
      color: #328856;
      font-size: 12pt;
      margin-bottom: 12px;
      font-weight: 100;
    }
    input {
      margin-left: 10px;
      width: 216px;
      height: 25px;
      border-radius: 17px;
      border: solid 1px rgba(55,182,113,.4);
      //::placeholder {
      //  font-family: 'ProximaNova';
      //  font-size: 40px;
      //}
    }
    .iconSearch {

      position: absolute;
      top: 49px;
      right: 26px;
      color: rgb(55,182,113);
    }
    input::placeholder {
      font-family: 'ProximaNova';
      font-size: 15px;
      font-style: italic;
      margin-left: 20px;
      display: inline-block;
    }
    .headerDash {
      margin-left: 10px;
      margin-top: 19px;
    }
  }
}
.ItemDash {
  margin-left: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.boxDropDownMenu {
  border-bottom: 1px solid rgb(203,199,197);
  margin-left: 10px;
  width: 100px;
  width: 223px;
  min-height: 30px;
  position: relative;
}
.headerDropDown {
  font-family: 'ProximaNova';
  margin-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  // width: inherit;
  // height: 30px;
  color: rgb(72,77,80);
  display: inline-flex;
  // float: left;
  span {
    line-height: 14px;
    display: inline-block;
    margin-top: 6px;
  }
  .count1 {
    color: rgb(0,28,98);
  }
  .count2 {
    color: rgb(156,166,160);
  }
}
//.dashBox {
//  border: 1px solid;
//  width: 197px;
//  min-height: 23px;
//  border-radius: 6px;
//  .dividerVertical {
//    float: left;
//    height: 100%;
//    width: 3px;
//    border-right: 1px solid;
//    background: rgb(216,216,216);
//    border-radius: 6px 0px 0px 6px;
//  }
//  .dashBoxText {
//    min-height: 23px;
//    margin-left: 3px;
//    border-radius: 0px 6px 6px 0px;
//    border-left: 1px solid;
//    background: #ffffff;
//    span {
//      float: left;
//      font-family: 'ProximaNova';
//      display: inline-block;
//      margin-left: 5px;
//      margin-top: 2px;
//    }
//  }
//
//}
//.dashBox:hover {
//  border: 1px solid;
//  width: 197px;
//  min-height: 23px;
//  border-radius: 6px;
//  //background: rgb(245,245,245);
//  .dividerVertical {
//    float: left;
//    height: 100%;
//    width: 3px;
//    border-right: 1px solid;
//    background: rgb(216,216,216);
//    border-radius: 6px 0px 0px 6px;
//  }
//  .dashBoxText {
//    min-height: 23px;
//    margin-left: 3px;
//    border-radius: 0px 6px 6px 0px;
//    background: rgb(245,245,245);
//    border-left: 1px solid;
//
//    span {
//      float: left;
//      font-family: 'ProximaNova';
//      display: inline-block;
//      margin-left: 5px;
//      margin-top: 2px;
//    }
//  }
//}
