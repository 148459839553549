@import '../../../variables.scss';

.boxRightPanel_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 238px;
  overflow: hidden;
  top: 50px;
  z-index: 301;
  // background: @overlayColor;
  background: transparent;
}

.ReportInfo {
  h4 {
    font-family: ProximaNova;
    font-size: 17px;
    color: #a0a7b2;
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    font-family: 'ProximaNova';
    color: #6a7074;
    font-size: 14px;
    margin: 0px;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .ReportInfo_value {
    margin-left: 10px;
  }
  .ReportInfo_description {
    margin-top: 20px;
    p {
      font-family: 'ProximaNova';
      color: #6a7074;
      font-size: 14px;
    }
  }
}

.boxRightPanel {
  position: fixed;
  width: 238px;
  height: 100%;
  left: 0px;
  overflow: hidden;
  top: 50px;
  z-index: 301;
  .boxRightPanelContent {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    width: 238px;
    height: 100%;
    right: 0px;
    overflow: auto;
    padding: 10px;
    // justify-content: space-between;
    //margin-left: 38px;
    // overflow: hidden;
    //box-shadow: 1px 0px 14px 0px rgba(0, 0, 0, 0.5);
    background: #fff;
    .header {
      height: 50px;
      width: 100%;
      .headerItemButtom {
        height: 100%;
        width: 50%;
        display: inline-block;
        .ButtonItem {
          font-family: ProximaNova;
          height: 73%;
          display: inline-block;
          padding-top: 13px;
          font-size: 12pt;
          padding-left: 19px;
          .divider.active {
            width: 100%;
            height: 4px;
            margin-top: 3px;
            background: #46b875;
          }
          .divider {
            width: 100%;
            height: 4px;
            margin-top: 3px;
            background: #ffffff;
          }
        }
      }
    }
    .bodyRightPanel {
      width: 100%;
      //background: #8c9790;
      height: calc(100% - 50px);
      .bodyItem {
        width: 100%;
        padding-left: 10px;
        position: relative;
        .titleSelect {
          font-family: ProximaNova;
          font-size: 11pt;
          font-style: italic;
          margin-bottom: 5px;
          display: inline-block;
          color: rgb(36, 49, 56);
        }
        select {
          width: 100%;
          margin-bottom: 10px;
          outline: none;
          border: none;
          font-family: ProximaNova;
          font-size: 10pt;
          color: rgb(36, 49, 56);
        }
      }
    }
    box-sizing: border-box;
  }
}

.arrow {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  width: 25px;
  text-align: center;
  /* padding-bottom: 3px; */
  box-sizing: border-box;
  //border-radius: 5px;
  background: #eef0f0;
  padding-top: 2px;
  pointer-events: none;
  i {
    height: 25px;
    display: inline-block;
    font-size: 20px;
    color: rgb(36, 41, 45);
  }
}

.styled-select {
  //background: url(http://i62.tinypic.com/15xvbd5.png) no-repeat 96% 0;
  height: 29px;
  overflow: hidden;
  width: 240px;
}

.styled-select select {
  background: transparent;
  border: none;
  font-size: 14px;
  height: 29px;
  padding: 0 5px; /* If you add too much padding here, the options won't show in IE */
  width: 268px;
}

.styled-select.slate {
  //background: url(http://i62.tinypic.com/2e3ybe1.jpg) no-repeat right center;
  height: 23px;
  width: 218px;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

.styled-select.slate select {
  border: 1px solid rgb(220, 220, 220);
  font-size: 13px;
  height: 23px;
  width: 268px;
}

/* -------------------- Rounded Corners */
.rounded {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.semi-square {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/* -------------------- Colors: Background */
//.slate   { background-color: #ddd; }
.green {
  background-color: #779126;
}
.blue {
  background-color: #3b8ec2;
}
.yellow {
  background-color: #eec111;
}
.black {
  background-color: #000;
}

/* -------------------- Colors: Text */
.slate select {
  color: #000;
}
.green select {
  color: #fff;
}
.blue select {
  color: #fff;
}
.yellow select {
  color: #000;
}
.black select {
  color: #fff;
}

/* -------------------- Select Box Styles: danielneumann.com Method */
/* -------------------- Source: http://danielneumann.com/blog/how-to-style-dropdown-with-css-only/ */
#mainselection select {
  border: 0;
  color: #eee;
  background: transparent;
  font-size: 20px;
  font-weight: bold;
  padding: 2px 10px;
  width: 378px;
  *width: 350px;
  *background: #58b14c;
  -webkit-appearance: none;
}

#mainselection {
  overflow: hidden;
  width: 350px;
  -moz-border-radius: 9px 9px 9px 9px;
  -webkit-border-radius: 9px 9px 9px 9px;
  border-radius: 9px 9px 9px 9px;
  box-shadow: 1px 1px 11px #330033;
  background: #58b14c url('http://i62.tinypic.com/15xvbd5.png') no-repeat scroll 319px center;
}

/* -------------------- Select Box Styles: stackoverflow.com Method */
/* -------------------- Source: http://stackoverflow.com/a/5809186 */
select#soflow,
select#soflow-color {
  -webkit-appearance: button;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#fafafa, #f4f4f4 40%, #e5e5e5);
  background-position: 97% center;
  background-repeat: no-repeat;
  border: 1px solid #aaa;
  color: #555;
  font-size: inherit;
  margin: 20px;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}

select#soflow-color {
  color: #fff;
  background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#779126, #779126 40%, #779126);
  background-color: #779126;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding-left: 15px;
}
