.radiusPlanBox {
    position: absolute;
    top: 0px;
    /* bottom: 0px; */
    right: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    overflow: auto;
    left: 0px;
}
/*body.infobar-offcanvas > div#ReactBox > div#MainComponent > div div.radiusPlanBox {*/
/*left: 248px;*/
/*}*/
/*body.sidebar-collapsed > div#ReactBox > div#MainComponent > div div.radiusPlanBox{*/
/*left: 48px;*/
/*}*/
.radiusPlanPanel {
    position: absolute;
    width: 659px;
    /* height: 400px; */
    left: calc((100% - 659px) / 2);
    top: 32px;
    border-radius: 3px;
    font-weight: 100;
}
.radiusTimesOfDayBox {
    background: #fff;
    width: 100%;
    height: 100%;
    /*margin-top: 10px;*/
    /* position: absolute; */
    /* top: 0px; */
}
div.time-picker input {
    float: left;
    width: 100%;
}
div.time-picker div.clock {
    position: absolute;
    z-index: 2;
    border-radius: 3px;
    padding: 16px;
    background: white;
    -webkit-box-shadow: 0 2px 8px rgba(100, 100, 100, 0.4);
    -moz-box-shadow: 0 2px 8px rgba(100, 100, 100, 0.4);
    box-shadow: 0 2px 8px rgba(100, 100, 100, 0.4);
}
div.time-picker div.clock.clock-show {
    visibility: visible;
    opacity: 1;
    transition: opacity 350ms ease;
}
div.time-picker div.clock.clock-hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 350ms, opacity 350ms ease;
}
div.time-picker div.clock.clock-hide .position,
div.time-picker div.clock.clock-hide .inner-face,
div.time-picker div.clock.clock-hide .long-hand,
div.time-picker div.clock.clock-hide .long-hand-attachment,
div.time-picker div.clock.clock-hide .ticks {
    transition: visibility 0s 350ms;
    visibility: hidden;
}
div.time-picker div.clock div.clock-face-wrapper {
    position: relative;
    background: white;
    border: solid 1px #dadada;
    border-radius: 100%;
    width: 151px;
    height: 151px;
}
div.time-picker div.clock div.am-pm-info {
    float: left;
    width: 100%;
    margin-top: 16px;
}
div.time-picker div.clock div.am-pm-info div.am,
div.time-picker div.clock div.am-pm-info div.pm {
    float: left;
    color: black;
    border: solid 1px #dadada;
    font-size: 0.7em;
    padding: 4px 7px;
    border-radius: 3px;
    background: white;
    cursor: pointer;
}
div.time-picker div.clock div.am-pm-info div.am:hover,
div.time-picker div.clock div.am-pm-info div.pm:hover {
    background: #eaeaea;
}
div.time-picker div.clock div.am-pm-info div.am.selected,
div.time-picker div.clock div.am-pm-info div.pm.selected {
    background: #026aa7;
    color: white;
}
div.time-picker div.clock div.am-pm-info div.pm {
    float: right;
}
div.time-picker div.clock div.am-pm-info div.time {
    float: left;
    color: #777;
    font-size: 0.9em;
    width: calc(100% - 68px);
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    height: 23px;
    line-height: 23px;
}
div.time-picker div.clock div.face {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background: white;
}
div.time-picker div.clock div.face.face-show {
    visibility: visible;
    opacity: 1;
    transition: opacity 350ms 175ms ease;
}
div.time-picker div.clock div.face.face-hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 350ms, opacity 350ms ease;
}
div.time-picker div.clock div.face div.centre {
    position: absolute;
    width: 100px;
    left: 25px;
    text-align: center;
    color: #555;
    top: 66px;
}
div.time-picker div.clock div.face div.centre div.prompt {
    font-size: 0.8em;
    text-transform: uppercase;
    color: #026aa7;
    font-weight: bold;
    line-height: normal;
    display: none;
    /* font-family: "Open Sans"; */
}
div.time-picker div.clock div.face div.centre div.am-pm {
    margin-top: 10px;
    line-height: normal;
}
div.time-picker div.clock div.face div.centre div.am-pm span {
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 0.8em;
    margin-right: 10px;
}
div.time-picker div.clock div.face div.centre div.am-pm span:hover {
    background: #eaeaea;
    color: black;
}
div.time-picker div.clock div.face div.centre div.am-pm span:last-child {
    margin-right: 0;
}
div.time-picker div.clock div.face div.centre div.am-pm span.selected {
    background: #026aa7;
    color: white;
}
div.time-picker div.clock div.face.am-pm .centre {
    top: 48px;
}
div.time-picker div.clock div.face div.position {
    position: absolute;
    color: black;
    font-size: 0.8em;
    z-index: 7;
    border-radius: 100%;
    cursor: pointer;
    width: 29px;
    display: inline-block;
    height: 29px;
    line-height: 29px;
    vertical-align: middle;
    text-align: center;
}
div.time-picker div.clock div.face div.position:hover {
    background: #eaeaea;
}
div.time-picker div.clock div.face div.position.selected {
    background: #026aa7;
    color: white;
}
div.time-picker div.clock div.face div.position.position-12 {
    left: calc(50% - 14.5px);
    top: 4px;
}
div.time-picker div.clock div.face div.position.position-1 {
    left: 87px;
    top: 12px;
}
div.time-picker div.clock div.face div.position.position-2 {
    left: 110px;
    top: 32px;
}
div.time-picker div.clock div.face div.position.position-3 {
    left: 118px;
    top: 60.5px;
}
div.time-picker div.clock div.face div.position.position-4 {
    left: 110px;
    top: 89px;
}
div.time-picker div.clock div.face div.position.position-5 {
    left: 87px;
    top: 111px;
}
div.time-picker div.clock div.face div.position.position-6 {
    left: 60.5px;
    top: 117px;
}
div.time-picker div.clock div.face div.position.position-7 {
    left: 34px;
    top: 111px;
}
div.time-picker div.clock div.face div.position.position-8 {
    left: 12px;
    top: 89px;
}
div.time-picker div.clock div.face div.position.position-9 {
    left: 4px;
    top: 60.5px;
}
div.time-picker div.clock div.face div.position.position-10 {
    left: 12px;
    top: 32px;
}
div.time-picker div.clock div.face div.position.position-11 {
    left: 34px;
    top: 12px;
}
div.time-picker div.clock div.face div.long-hand {
    height: 75px;
    width: 1px;
    background: #026aa7;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 6;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}
div.time-picker div.clock div.face div.long-hand-attachment {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    z-index: 7;
    background: #026aa7;
    position: absolute;
    top: 72px;
    left: 73px;
}
div.time-picker div.clock div.face div.inner-face {
    position: absolute;
    z-index: 5;
    width: 79px;
    height: 79px;
    border: solid 1px #dadada;
    top: 35px;
    left: 35px;
    border-radius: 100%;
    background: white;
}
div.time-picker div.clock div.face div.ticks div.tick {
    height: 75px;
    width: 1px;
    background: white;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 3;
    -webkit-transform-origin: 0% 100%;
}
div.time-picker div.clock div.face div.ticks div.tick div {
    position: absolute;
    z-index: 4;
    background: #aaa;
    top: 0;
    height: 4px;
    width: 1px;
}
div.time-picker div.clock div.face div.ticks div.tick.big div {
    height: 7px;
}

.dtp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2000;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.dtp > .dtp-content {
    background: #fff;
    max-width: 300px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    max-height: 520px;
    position: relative;
    left: 50%;
}
.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
    background: #689f38;
    color: #fff;
    text-align: center;
    padding: 0.3em;
}

.dtp div.dtp-date,
.dtp div.dtp-time {
    background: #8bc34a;
    text-align: center;
    color: #fff;
    padding: 10px;
}
.dtp div.dtp-date > div {
    padding: 0;
    margin: 0;
}
.dtp div.dtp-actual-month {
    font-size: 1.5em;
}
.dtp div.dtp-actual-num {
    font-size: 3em;
    line-height: 0.9;
}
.dtp div.dtp-actual-maxtime {
    font-size: 3em;
    line-height: 0.9;
}
.dtp div.dtp-actual-year {
    font-size: 1.5em;
    color: #dcedc8;
}
.dtp div.dtp-picker {
    padding: 1em;
    text-align: center;
}

.dtp div.dtp-picker-month,
.dtp div.dtp-actual-time {
    font-weight: 500;
    text-align: center;
}
.dtp div.dtp-picker-month {
    padding-bottom: 20px !important;
    text-transform: uppercase !important;
}

.dtp .dtp-close {
    position: absolute;
    top: 0.5em;
    right: 1em;
}
.dtp .dtp-close > a {
    color: #fff;
}
.dtp .dtp-close > a > i {
    font-size: 1em;
}

.dtp table.dtp-picker-days {
    margin: 0;
    min-height: 251px;
}
.dtp table.dtp-picker-days,
.dtp table.dtp-picker-days tr,
.dtp table.dtp-picker-days tr > td {
    border: none;
}
.dtp table.dtp-picker-days tr > td {
    font-weight: 700;
    font-size: 0.8em;
    text-align: center;
    padding: 0.5em 0.3em;
}
.dtp table.dtp-picker-days tr > td > span.dtp-select-day {
    color: #bdbdbd !important;
}
.dtp table.dtp-picker-days tr > td > a,
.dtp .dtp-picker-time > a {
    color: #212121;
    text-decoration: none;
    padding: 0.4em 0.5em 0.5em 0.6em;
    border-radius: 50% !important;
}
.dtp table.dtp-picker-days tr > td > a.selected {
    background: #8bc34a;
    color: #fff;
}
.dtp table.dtp-picker-days tr > th {
    color: #757575;
    text-align: center;
    font-weight: 700;
    padding: 0.4em 0.3em;
}

.dtp .p10 > a {
    color: #689f38;
    text-decoration: none;
}
.dtp .p10 {
    width: 10%;
    display: inline-block;
}
.dtp .p20 {
    width: 20%;
    display: inline-block;
}
.dtp .p60 {
    width: 60%;
    display: inline-block;
}
.dtp .p80 {
    width: 80%;
    display: inline-block;
}

.dtp a.dtp-meridien-am,
.dtp a.dtp-meridien-pm {
    position: relative;
    top: 10px;
    color: #212121;
    font-weight: 500;
    padding: 0.7em 0.5em;
    border-radius: 50% !important;
    text-decoration: none;
    background: #eee;
    font-size: 1em;
}
.dtp .dtp-actual-meridien a.selected {
    background: #689f38;
    color: #fff;
}

.dtp .dtp-picker-time > .dtp-select-hour {
    cursor: pointer;
}
.dtp .dtp-picker-time > .dtp-select-minute {
    cursor: pointer;
}

.dtp .dtp-buttons {
    padding: 0 1em 1em 1em;
    text-align: right;
}

.dtp.hidden,
.dtp .hidden {
    display: none;
}
.dtp .invisible {
    visibility: hidden;
}

.dtp .left {
    float: left;
}
.dtp .right {
    float: right;
}
.dtp .clearfix {
    clear: both;
}

.dtp .center {
    text-align: center;
}

.radiusTimesTitles {
    width: 30%;
    height: 100%;
    display: inline-block;
    float: left;
}
.radiusTimesDiscription {
    width: 70%;
    /*height: 100%;*/
    display: inline-block;
    float: left;
}
.radiusTimeDayFrom {
    width: 68px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* overflow: hidden; */
    /* text-align: center; */
    /* margin-top: 16px; */
    /* position: relative; */
    /* bottom: 13px; */
    /* float: left; */
    /* height: 100%; */
    height: 26px;
    border: 1px solid rgb(227, 227, 227);
    border-radius: 3px;
}
.radiusTimePicker:nth-child(1) {
    /*border-left: 2px solid; */
    /* border-top: 2px solid; */
    /* border-bottom: 2px solid; */
    /*border-right: 1px dotted;*/
    /*border-color: rgb(227,227,227);*/
    width: 22px;
    /* border-radius: 3px 0 0 3px; */
}
.radiusTimePicker:nth-child(3) {
    /*border-top: 2px solid;*/
    /*border-right: 2px solid;*/
    /*border-bottom: 2px solid;*/
    /*border-left: 1px dotted;*/
    /*border-color: rgb(227,227,227);*/
    width: 22px;
    /*border-radius: 0 3px 3px 0;*/
}
.radiusTimeDayTo {
    width: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 16px; */
    /* position: relative; */
    /* bottom: 13px; */
    /* float: left; */
    /* height: 100%; */
    height: 26px;
    border: 1px solid rgb(227, 227, 227);
    border-radius: 3px;
}
.radiusTimeDayFrom.disapledTime {
    color: gray !important;
    background: rgb(250, 250, 250);
}
.radiusTimeDayFrom.disapledTime > div {
    color: gray !important;
}
.radiusTimeDayFrom.disapledTime > div > select {
    color: rgba(128, 128, 128, 0.5);
}
.radiusTimeDayTo.disapledTime {
    color: gray !important;
    background: rgb(250, 250, 250);
}
.radiusTimeDayTo.disapledTime > div {
    color: gray !important;
}
.radiusTimeDayTo.disapledTime > p,
.radiusTimeDayFrom.disapledTime > p {
    color: rgba(128, 128, 128, 0.5) !important;
}
.radiusTimeDayTo.disapledTime > div > select {
    color: rgba(128, 128, 128, 0.5);
}
.radiusTimeBox {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    /*margin-top: 60px;*/
}
.radiusTimeBox > div {
    display: block;
    width: 249px;
    height: 60px;
    float: left;
    padding-top: 8px;
    /* padding-left: 113px; */
}
.radiusTimeBox p {
    width: 20px;
    display: inline-block;
    text-align: center;
}
.radiusbixDateMargin {
    margin-top: 10px;
    border-bottom: 1px dashed rgb(232, 232, 232);
    height: 52px;
    position: relative;
}
.radiusTimeBox span {
    display: inline-block;
    position: relative;
    top: 10px;
    left: 56px;
    /*font-size: 18px;*/
}
.radiusTimeBox button {
    display: inline-block;
    position: absolute;
    padding: 5px 10px;
    top: 6px;
    border-radius: 3px;
    border: 1px solid rgb(224, 224, 224);
    /* background: rgba(0,0,0,0.0); */
    right: 20px;
}
.radiusTimeBox input {
    width: 24px;
}
.radiusTimesTitles span {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 20px;
    text-align: left;
    /* font-size: 18px; */
    height: 52px;
    border-bottom: 1px dashed rgb(232, 232, 232);
}
.radiusDayDiscription {
    width: 100%;
    height: 52px;
    /*margin-top: 60px;*/
}
.radiusDayDiscription > span {
    display: inline-block;
    position: relative;
    top: 10px;
    left: 56px;
    /*font-size: 18px;*/
}
.radiusDayDiscription button {
    display: inline-block;
    position: absolute;
    padding: 5px 10px;
    top: 6px;
    border-radius: 3px;
    border: 1px solid rgb(224, 224, 224);
    /* background: rgba(0,0,0,0.0); */
    right: 20px;
}
.radiusWeekBox {
    width: 100%;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 3px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}
.radiusWeekBox div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.radiusWeekBox div span {
    vertical-align: middle;
    margin-top: 4px;
    display: inline-block;
    color: #455a64;
}
.radiusWeekBox div.activeDay span {
    color: #fff;
}
.radiusBrowserLangsBox {
    width: 100%;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 3px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.radiusBrowserLangsBox div {
    /*background: aqua;*/
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.radiusBrowserLangsBox div span {
    vertical-align: middle;
}
.activeDay {
    background: rgb(69, 185, 118);
}
.notActiveAllDay {
    background: rgba(0, 0, 0, 0);
    border: 1px solid rgb(232, 232, 232);
    border-radius: 3px;
}
.radiusDateDiscription {
    width: 100%;
    height: 52px;
    /*margin-top: 60px;*/
}
.radiusDateDiscription > span {
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 305px;
}
.radiusDateDiscription button {
    display: inline-block;
    position: absolute;
    padding: 5px 10px;
    top: 6px;
    border-radius: 3px;
    border: 1px solid rgb(224, 224, 224);
    /* background: rgba(0,0,0,0.0); */
    right: 20px;
}
.radiusDateBox {
    height: 32px;
    width: 248px;
    float: left;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 3px;
    margin-top: 5px;
    background: #fff;
    cursor: pointer;
}
.radiusDateBox div#minDateSelect,
.radiusDateBox div#maxDateSelect {
    display: inline-block;
    /* font-size: 18px; */
    width: 112px;
    text-align: center;
    height: 100%;
    padding-top: 5px;
    /* border: 1px solid rgb(224, 224, 224); */
    cursor: pointer;
    float: left;
}
/*.radiusDateBox div#minDateSelect {
	border-right: 1px solid rgb(223,223,223);
}
.radiusDateBox div#maxDateSelect {
	border-left: 1px solid rgb(223,223,223);
}*/
.disabledDate {
    background: rgb(250, 250, 250);
}
.calendarBox2 {
    position: absolute;
    top: 0px;
    left: 0px;
    background: #fff;
    width: 659px;
    z-index: 1;
    height: 362px;
    border-radius: 3px;
    text-align: center;
}
.disabledSpanTitle {
    color: gray;
}
.calendarBox2 > div {
    display: inline-block;
    margin-top: 15px;
}
.calendarBox2 > div:nth-child(1) {
    margin-right: 15px;
}
.radiusTimesDiscription label {
    margin-top: 10px;
    margin-right: 10px;
}
.radiusTimeBoxFooter {
}
.radiusTimeBoxFooter button {
    margin-left: 7px;
    background: #fff;
    /* border: none; */
    /* padding: 5px; */
    border: 1px solid rgb(232, 232, 232);
    border-radius: 3px;
    padding: 0px 10px;
    height: 30px;
    /* font-family: 'Open Sans', sans-serif; */
    font-weight: 100;
}

.radiusTimeBoxFooter button:nth-child(2) {
    background: rgb(250, 250, 250);
}
.radiusTimeBoxFooter button:nth-child(1) {
    background: rgb(69, 185, 118);
    color: #fff !important;
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalVirginStyle > div .radiusTimeBoxFooter button:nth-child(2) {
    background: rgb(255, 92, 92);
    color: #fff;
}
.spinerLoaderRadiusLoadPlan {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
}

/*calendar*/

.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}
button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled].rbc-btn {
    cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
/*.rbc-calendar {*/
/*box-sizing: border-box;*/
/*height: 100%;*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: column;*/
/*flex-direction: column;*/
/*-ms-flex-align: stretch;*/
/*align-items: stretch;*/
/*background: #fff;*/
/*}*/
/*.rbc-calendar *,*/
/*.rbc-calendar *:before,*/
/*.rbc-calendar *:after {*/
/*box-sizing: inherit;*/
/*}*/
/*.rbc-abs-full,*/
/*.rbc-row-bg {*/
/*overflow: hidden;*/
/*position: absolute;*/
/*top: 0;*/
/*left: 0;*/
/*right: 0;*/
/*bottom: 0;*/
/*}*/
/*.rbc-ellipsis,*/
/*.rbc-event-label,*/
/*.rbc-row-segment .rbc-event-content,*/
/*.rbc-show-more {*/
/*display: block;*/
/*overflow: hidden;*/
/*text-overflow: ellipsis;*/
/*white-space: nowrap;*/
/*}*/
/*.rbc-rtl {*/
/*direction: rtl;*/
/*}*/
/*.rbc-off-range {*/
/*color: #b3b3b3;*/
/*}*/
/*.rbc-header {*/
/*overflow: hidden;*/
/*text-overflow: ellipsis;*/
/*white-space: nowrap;*/
/*padding: 0 3px;*/
/*text-align: center;*/
/*vertical-align: middle;*/
/*font-weight: bold;*/
/*font-size: 90%;*/
/*min-height: 0;*/
/*}*/
/*.rbc-header > a,*/
/*.rbc-header > a:active,*/
/*.rbc-header > a:visited {*/
/*color: inherit;*/
/*text-decoration: none;*/
/*}*/
/*.rbc-toolbar {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-align: center;*/
/*align-items: center;*/
/*margin-bottom: 10px;*/
/*font-size: 16px;*/
/*}*/
/*!*.rbc-toolbar .rbc-toolbar-label {*!*/
/*!*!*width: 100%;*!*!*/
/*!*padding: 0 10px;*!*/
/*!*text-align: center;*!*/
/*!*}*!*/
/*.rbc-toolbar button {*/
/*color: #373a3c;*/
/*display: inline-block;*/
/*margin: 0;*/
/*text-align: center;*/
/*vertical-align: middle;*/
/*background: none;*/
/*background-image: none;*/
/*border: 1px solid #ccc;*/
/*padding: .375rem 1rem;*/
/*border-radius: 4px;*/
/*line-height: normal;*/
/*white-space: nowrap;*/
/*}*/
/*.rbc-toolbar button:active,*/
/*.rbc-toolbar button.rbc-active {*/
/*background-image: none;*/
/*box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);*/
/*background-color: #e6e6e6;*/
/*border-color: #adadad;*/
/*}*/
/*.rbc-toolbar button:active:hover,*/
/*.rbc-toolbar button.rbc-active:hover,*/
/*.rbc-toolbar button:active:focus,*/
/*.rbc-toolbar button.rbc-active:focus {*/
/*color: #373a3c;*/
/*background-color: #d4d4d4;*/
/*border-color: #8c8c8c;*/
/*}*/
/*.rbc-toolbar button:focus {*/
/*color: #373a3c;*/
/*background-color: #e6e6e6;*/
/*border-color: #adadad;*/
/*}*/
/*.rbc-toolbar button:hover {*/
/*color: #373a3c;*/
/*background-color: #e6e6e6;*/
/*border-color: #adadad;*/
/*}*/
/*.rbc-btn-group {*/
/*display: inline-block;*/
/*white-space: nowrap;*/
/*}*/
/*.rbc-btn-group > button:first-child:not(:last-child) {*/
/*border-top-right-radius: 0;*/
/*border-bottom-right-radius: 0;*/
/*}*/
/*.rbc-btn-group > button:last-child:not(:first-child) {*/
/*border-top-left-radius: 0;*/
/*border-bottom-left-radius: 0;*/
/*}*/
/*.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {*/
/*border-radius: 4px;*/
/*border-top-left-radius: 0;*/
/*border-bottom-left-radius: 0;*/
/*}*/
/*.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {*/
/*border-radius: 4px;*/
/*border-top-right-radius: 0;*/
/*border-bottom-right-radius: 0;*/
/*}*/
/*.rbc-btn-group > button:not(:first-child):not(:last-child) {*/
/*border-radius: 0;*/
/*}*/
/*.rbc-btn-group button + button {*/
/*margin-left: -1px;*/
/*}*/
/*.rbc-rtl .rbc-btn-group button + button {*/
/*margin-left: 0;*/
/*margin-right: -1px;*/
/*}*/
/*.rbc-btn-group + .rbc-btn-group,*/
/*.rbc-btn-group + button {*/
/*margin-left: 10px;*/
/*}*/
/*.rbc-event {*/
/*cursor: pointer;*/
/*padding: 2px 5px;*/
/*background-color: #3174ad;*/
/*border-radius: 5px;*/
/*color: #fff;*/
/*}*/
/*.rbc-event.rbc-selected {*/
/*background-color: #265985;*/
/*}*/
/*.rbc-event-label {*/
/*font-size: 80%;*/
/*}*/
/*.rbc-event-overlaps {*/
/*box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);*/
/*}*/
/*.rbc-event-continues-after {*/
/*border-top-right-radius: 0;*/
/*border-bottom-right-radius: 0;*/
/*}*/
/*.rbc-event-continues-prior {*/
/*border-top-left-radius: 0;*/
/*border-bottom-left-radius: 0;*/
/*}*/
/*.rbc-row {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: row;*/
/*flex-direction: row;*/
/*}*/
/*.rbc-row-segment {*/
/*padding: 0 1px 1px 1px;*/
/*}*/
/*.rbc-selected-cell {*/
/*background-color: #000;*/
/*color: white;*/
/*opacity: 0.05;*/
/*}*/
/*.rbc-show-more {*/
/*background-color: rgba(255, 255, 255, 0.3);*/
/*z-index: 4;*/
/*font-weight: bold;*/
/*font-size: 85%;*/
/*height: auto;*/
/*line-height: normal;*/
/*white-space: nowrap;*/
/*}*/
/*.rbc-month-view {*/
/*position: relative;*/
/*border: 1px solid #DDD;*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: column;*/
/*flex-direction: column;*/
/*-ms-flex: 1 0 0;*/
/*flex: 1 0 0;*/
/*width: 100%;*/
/*height: 100%;*/
/*}*/
/*.rbc-month-view .rbc-header {*/
/*border-bottom: 1px solid #DDD;*/
/*}*/
/*.rbc-month-view .rbc-header + .rbc-header {*/
/*border-left: 1px solid #DDD;*/
/*}*/
/*.rbc-rtl .rbc-month-view .rbc-header + .rbc-header {*/
/*border-left-width: 0;*/
/*border-right: 1px solid #DDD;*/
/*}*/
/*.rbc-month-header {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: row;*/
/*flex-direction: row;*/
/*}*/
/*.rbc-month-row {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*position: relative;*/
/*-ms-flex-direction: column;*/
/*flex-direction: column;*/
/*-ms-flex: 1 0 0;*/
/*flex: 1 0 0;*/
/*!*overflow: hidden;*!*/
/*height: 100%;*/
/*}*/
/*.rbc-month-row + .rbc-month-row {*/
/*border-top: 1px solid #DDD;*/
/*}*/
/*.rbc-date-cell {*/
/*padding-right: 5px;*/
/*text-align: right;*/
/*position: relative;*/
/*}*/
/*.rbc-date-cell.rbc-now {*/
/*font-weight: bold;*/
/*}*/
/*.rbc-date-cell > a,*/
/*.rbc-date-cell > a:active,*/
/*.rbc-date-cell > a:visited {*/
/*color: inherit;*/
/*text-decoration: none;*/
/*cursor: default;*/
/*}*/
/*.rbc-row-content {*/
/*position: relative;*/
/*!*z-index: 4;*!*/
/*height: 36px;*/
/*}*/
/*.rbc-row-bg {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: row;*/
/*flex-direction: row;*/
/*-ms-flex: 1 0 0;*/
/*flex: 1 0 0;*/
/*overflow: hidden;*/
/*}*/
/*.rbc-day-bg + .rbc-day-bg {*/
/*border-left: 1px solid #DDD;*/
/*}*/
/*.rbc-rtl .rbc-day-bg + .rbc-day-bg {*/
/*border-left-width: 0;*/
/*border-right: 1px solid #DDD;*/
/*}*/
/*.rbc-overlay {*/
/*position: absolute;*/
/*z-index: 5;*/
/*border: 1px solid #e5e5e5;*/
/*background-color: #fff;*/
/*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);*/
/*padding: 10px;*/
/*}*/
/*.rbc-overlay > * + * {*/
/*margin-top: 1px;*/
/*}*/
/*.rbc-overlay-header {*/
/*border-bottom: 1px solid #e5e5e5;*/
/*margin: -10px -10px 5px -10px;*/
/*padding: 2px 10px;*/
/*}*/
/*.rbc-agenda-view {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: column;*/
/*flex-direction: column;*/
/*-ms-flex: 1 0 0;*/
/*flex: 1 0 0;*/
/*overflow: auto;*/
/*}*/
/*.rbc-agenda-view table {*/
/*width: 100%;*/
/*border: 1px solid #DDD;*/
/*}*/
/*.rbc-agenda-view table tbody > tr > td {*/
/*padding: 5px 10px;*/
/*vertical-align: top;*/
/*}*/
/*.rbc-agenda-view table .rbc-agenda-time-cell {*/
/*padding-left: 15px;*/
/*padding-right: 15px;*/
/*text-transform: lowercase;*/
/*}*/
/*.rbc-agenda-view table tbody > tr > td + td {*/
/*border-left: 1px solid #DDD;*/
/*}*/
/*.rbc-rtl .rbc-agenda-view table tbody > tr > td + td {*/
/*border-left-width: 0;*/
/*border-right: 1px solid #DDD;*/
/*}*/
/*.rbc-agenda-view table tbody > tr + tr {*/
/*border-top: 1px solid #DDD;*/
/*}*/
/*.rbc-agenda-view table thead > tr > th {*/
/*padding: 3px 5px;*/
/*text-align: left;*/
/*border-bottom: 1px solid #DDD;*/
/*}*/
/*.rbc-rtl .rbc-agenda-view table thead > tr > th {*/
/*text-align: right;*/
/*}*/
/*.rbc-agenda-time-cell {*/
/*text-transform: lowercase;*/
/*}*/
/*.rbc-agenda-time-cell .rbc-continues-after:after {*/
/*content: ' »';*/
/*}*/
/*.rbc-agenda-time-cell .rbc-continues-prior:before {*/
/*content: '« ';*/
/*}*/
/*.rbc-agenda-date-cell,*/
/*.rbc-agenda-time-cell {*/
/*white-space: nowrap;*/
/*}*/
/*.rbc-agenda-event-cell {*/
/*width: 100%;*/
/*}*/
/*.rbc-time-column {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: column;*/
/*flex-direction: column;*/
/*min-height: -webkit-min-content;*/
/*min-height: -moz-min-content;*/
/*min-height: min-content;*/
/*}*/
/*.rbc-time-column .rbc-timeslot-group {*/
/*-ms-flex: 1;*/
/*flex: 1;*/
/*}*/
/*.rbc-time-gutter,*/
/*.rbc-header-gutter {*/
/*-ms-flex: none;*/
/*flex: none;*/
/*}*/
/*.rbc-label {*/
/*padding: 0 5px;*/
/*}*/
/*.rbc-day-slot {*/
/*position: relative;*/
/*}*/
/*.rbc-day-slot .rbc-event {*/
/*border: 1px solid #265985;*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*max-height: 100%;*/
/*-ms-flex-flow: column wrap;*/
/*flex-flow: column wrap;*/
/*-ms-flex-align: start;*/
/*align-items: flex-start;*/
/*overflow: hidden;*/
/*}*/
/*.rbc-day-slot .rbc-event-label {*/
/*-ms-flex: none;*/
/*flex: none;*/
/*padding-right: 5px;*/
/*width: auto;*/
/*}*/
/*.rbc-day-slot .rbc-event-content {*/
/*width: 100%;*/
/*-ms-flex: 1 1 0;*/
/*flex: 1 1 0;*/
/*word-wrap: break-word;*/
/*line-height: 1;*/
/*height: 100%;*/
/*min-height: 1em;*/
/*}*/
/*.rbc-day-slot .rbc-time-slot {*/
/*border-top: 1px solid #f7f7f7;*/
/*}*/
/*.rbc-time-slot {*/
/*-ms-flex: 1 0 0;*/
/*flex: 1 0 0;*/
/*}*/
/*.rbc-time-slot.rbc-now {*/
/*font-weight: bold;*/
/*}*/
/*.rbc-timeslot-group {*/
/*border-bottom: 1px solid #DDD;*/
/*min-height: 40px;*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-flow: column nowrap;*/
/*flex-flow: column nowrap;*/
/*}*/
/*.rbc-day-header {*/
/*text-align: center;*/
/*}*/
/*.rbc-day-slot .rbc-event {*/
/*position: absolute;*/
/*}*/
/*.rbc-slot-selection {*/
/*z-index: 10;*/
/*position: absolute;*/
/*cursor: default;*/
/*background-color: #000;*/
/*color: white;*/
/*opacity: .5;*/
/*font-size: 75%;*/
/*padding: 3px;*/
/*}*/
/*.rbc-time-view {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex-direction: column;*/
/*flex-direction: column;*/
/*-ms-flex: 1;*/
/*flex: 1;*/
/*width: 100%;*/
/*border: 1px solid #DDD;*/
/*min-height: 0;*/
/*}*/
/*.rbc-time-view .rbc-time-gutter {*/
/*white-space: nowrap;*/
/*}*/
/*.rbc-time-view .rbc-allday-cell {*/
/*width: 100%;*/
/*position: relative;*/
/*}*/
/*.rbc-time-view .rbc-row {*/
/*min-height: 20px;*/
/*}*/
/*.rbc-time-header {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex: 0 0 auto;*/
/*flex: 0 0 auto;*/
/*-ms-flex-direction: column;*/
/*flex-direction: column;*/
/*}*/
/*.rbc-time-header.rbc-overflowing {*/
/*border-right: 1px solid #DDD;*/
/*}*/
/*.rbc-rtl .rbc-time-header.rbc-overflowing {*/
/*border-right-width: 0;*/
/*border-left: 1px solid #DDD;*/
/*}*/
/*.rbc-time-header > .rbc-row > * + * {*/
/*border-left: 1px solid #DDD;*/
/*}*/
/*.rbc-rtl .rbc-time-header > .rbc-row > * + * {*/
/*border-left-width: 0;*/
/*border-right: 1px solid #DDD;*/
/*}*/
/*.rbc-time-header > .rbc-row:first-child {*/
/*border-bottom: 1px solid #DDD;*/
/*}*/
/*.rbc-time-header .rbc-gutter-cell {*/
/*-ms-flex: none;*/
/*flex: none;*/
/*}*/
/*.rbc-time-header > .rbc-gutter-cell + * {*/
/*width: 100%;*/
/*}*/
/*.rbc-time-content {*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-ms-flex: 1 1 0%;*/
/*flex: 1 1 0%;*/
/*-ms-flex-align: stretch;*/
/*align-items: stretch;*/
/*width: 100%;*/
/*border-top: 2px solid #DDD;*/
/*overflow-y: auto;*/
/*}*/
/*.rbc-time-content > .rbc-time-gutter {*/
/*-ms-flex: none;*/
/*flex: none;*/
/*}*/
/*.rbc-time-content > * + * > * {*/
/*border-left: 1px solid #DDD;*/
/*}*/
/*.rbc-rtl .rbc-time-content > * + * > * {*/
/*border-left-width: 0;*/
/*border-right: 1px solid #DDD;*/
/*}*/
/*.rbc-time-content > .rbc-day-slot {*/
/*width: 100%;*/
/*}*/
.radiusCalendarBox {
    /*height: 500px;*/
    /*width: 50%;*/
    float: right;
    padding-right: 10px;
    padding-bottom: 12px;
}
.dateSpicker {
    width: 100%;
    height: 4px;
    background: green;
    margin: 2px;
    /*border-radius: 2px;*/
}
.radiusTemplateBox {
    width: calc(100% - 238px - 83px);
    float: left;
    background: #fff;
}
/*body.infobar-offcanvas > div#ReactBox > div#MainComponent > div div.radiusCalendarBox {
	width: 40%;
}
body.infobar-offcanvas.sidebar-collapsed > div#ReactBox > div#MainComponent > div div.radiusCalendarBox {
	width: 50%;
}*/
.radiusCalendarBoxItem {
    width: 298px;
    height: 360px;
    /* padding: 10px; */
    background: #fff;
    /* margin: auto; */
    padding-top: 11px;
    box-sizing: border-box;
    padding-bottom: 85px;
}
.boxBoxes > span.titleButton {
    margin-right: 11px;
}
.boxButtonLang {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 2px;
}
.itemButton {
    /*text-transform: uppercase;*/
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgb(221, 221, 221);
    font-size: 13px;
    padding-top: 1px;
    background: rgb(221, 221, 221);
}
.itemButton.active {
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgb(221, 221, 221);
    background: #fff;
}
.boxBoxes {
    display: flex;
    /* justify-content: center; */
    height: 20px;
    align-items: center;
    margin-bottom: 11px;
}
.radiusPlanButtonDisabled {
    position: absolute;
    width: 100%;
    height: 29px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    top: 143px;
}
.radiusBoxPlanCard {
    width: 297px;
    height: 112px;
    background: #fff;
    margin-top: 11px;
    padding: 5px;
    cursor: pointer;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-rows: 100%;
}
.titleSchedulesAfter {
    margin-top: 10px;
    text-align: center;
    width: 300px;
    margin-bottom: 57px;
}
.radiusBoxPlanCard img {
    /*width: 200px;*/
    height: 100%;
    float: left;
}
.radiusBoxPlanCard span {
    width: 100%;
    display: inline-block;
    font-size: 11px;
    /* font-family: 'Open Sans', sans-serif; */
    /* line-height: 1.54; */
    line-height: 1.8;
    /* text-transform: uppercase; */
    font-weight: 400;
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
.radiusBoxPlanCard h6 {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 25px;
    padding: 2px 11px;
    color: #fff;
    min-height: 19px;
    /* font-family: 'Open Sans','Droid Sans',Tahoma,Arial,sans-serif; */
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
}

.radiusBoxPlanCard div {
    width: 209px;
    /*min-width: 268px;*/
    display: inline-block;
    height: 100%;
    margin-left: 10px;
    position: relative;
    float: right;
    /*overflow: auto;*/
}
.radiusBoxPlanCard button {
    position: absolute;

    top: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0);
    border: none;
    font-size: 13px;
    color: rgb(69, 90, 100);
}
.radiusTimesOfDayBoxTitle {
    text-align: left;
    display: block;
    height: 80px;
    width: 100%;
    float: left;
    border-bottom: 1px solid rgb(232, 232, 232);
    /*padding: 20px;*/
    /* text-transform: uppercase; */
}
.radiusTimesOfDayBoxTitle span {
    font-size: 34px;
    position: absolute;
    top: 12px;
    right: 20px;
    color: rgb(213, 214, 215);
    cursor: pointer;
    font-weight: normal;
}
.activeAllDay {
    background: rgb(69, 185, 118);
    color: #fff !important;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 3px;
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalVirginStyle > div .activeAllDay {
    background: rgb(252, 93, 96);
    color: #fff !important;
}
.activeDay {
    background: rgb(69, 185, 118);
    color: #fff !important;
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalVirginStyle > div .activeDay {
    background: rgb(252, 93, 96);
    color: #fff !important;
}
.radiusTimesOfDayBoxTitle > h2 {
    display: inline-block;
    margin-top: 23px;
    margin-left: 20px;
    /*text-transform: uppercase;*/
    font-size: 27px;
    /* font-family: 'Open Sans', sans-serif; */
    font-weight: 100;
}
.radiusTimePicker {
    display: flex;
    justify-content: center;
    /*border: 1px dotted;*/
    border-color: rgb(227, 227, 227);
    position: relative;
    width: 16px;
    height: 22px;
    cursor: pointer;
    margin: 5px;
}
.radiusTimePicker > select {
    width: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 22px;
    border: none;
    background: none;
    cursor: pointer;
}
.tultipCurrent {
    position: absolute;
    top: 44px;
    left: 14px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    display: none;
    z-index: 1;
    pointer-events: none;
}
.tultipPrevious {
    position: absolute;
    top: 44px;
    left: 14px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    display: none;
    z-index: 1;
    pointer-events: none;
}
.tultipNext {
    position: absolute;
    top: 44px;
    left: 14px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    display: none;
    z-index: 1;
    pointer-events: none;
}
.buttonBigCalendarPrevious:hover .tultipPrevious {
    display: block;
}
.buttonBigCalendarCurrent:hover .tultipCurrent {
    display: block;
}
.buttonBigCalendarNext:hover .tultipNext {
    display: block;
}
.tultipCalendarCell {
    width: 95px;
    width: initial;
    height: 150px;
    background: rgba(250, 250, 250, 1);
    position: absolute;
    top: -62px;
    /* left: -20px; */
    right: 38px;
    border-radius: 3px;
    text-align: left;
    color: rgb(69, 90, 100);
    display: none;
    pointer-events: none;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    z-index: 1;
    border: 1px solid rgba(69, 90, 100, 0.4);
}
.tultipCalendarCell::after {
    content: '';
    position: absolute;
    right: 22px;
    visibility: hidden;
    /* top: 121px; */
    bottom: -30px;
    border: 10px solid transparent;
    border-top: 20px solid rgba(0, 0, 0, 0.5);
}
.rbc-date-cell:hover > .tultipCalendarCell {
    display: block;
}
.tultipCalendarCell:hover {
    display: none;
}
.tultipCalendarCell span {
    display: inline-block;
    font-size: 11px;
    /* margin-left: 4px; */
    color: #fff;
    font-weight: 100;
    width: 91px;
    text-align: center;
    line-height: 12px;
}
.tultipCalendarCell span:nth-child(1) {
    width: 67px;
    text-align: center;
    /* margin-left: 12px; */
}
.tultipCalendarCell img {
    height: 100px;
    /*margin-left: 12px;*/
}
.tultipCalendarCell span.tultipDefaultCalendar {
    display: inline-block;
    width: 100%;
    margin: 0px;
    text-align: center;
    font-size: 21px;
    margin-top: 50px;
}
.calendarBox2 > button {
    position: absolute;
    top: 300px;
    left: calc((100% - 74px) / 2);
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalShopsterStyle > div .calendarBox2 > button {
    background: rgb(69, 185, 118);
    color: #fff;
}
body.infobar-offcanvas > div#ReactBox > div#MainComponent.globalVirginStyle > div .calendarBox2 > button {
    background: rgb(252, 93, 96);
    color: #fff;
}
.templateNotAuth {
    text-align: left;
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 48px;
    left: 2px;
    color: #fff;
    padding: 5px;
    width: 197px;
    border-radius: 3px;
    z-index: 10;
    pointer-events: none;
}
.templateNotAuth::after {
    content: '';
    position: absolute;
    left: 38px;
    /* top: 121px; */
    bottom: -42px;
    border: 10px solid transparent;
    border-top: 32px solid rgba(0, 0, 0, 0.8);
}
.radiusPlanButtonDisabled:hover + .templateNotAuth {
    display: block;
    pointer-events: none;
}
.defaultComponent {
    position: absolute;
    top: 0px;
    left: 0px;
    /* background: aqua; */
    bottom: 0px;
    right: 0px;
    pointer-events: none;
}
.defaultComponent div {
    width: 100%;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
    z-index: 10;
}
.defaultComponent div:nth-child(1) {
    margin-top: 81px;
}
.defaultComponent div:nth-child(2) {
}
.defaultComponent div:nth-child(3) {
}
.defaultComponent div.tultipDafault {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 196px;
    height: inherit;
    z-index: 100;
    top: 12px;
    color: #fff;
    border-radius: 3px;
    pointer-events: none;
    display: none;
}
.defaultComponent:hover div.tultipDafault {
    display: block;
}
.defaultComponent div.tultipDafault::after {
    content: '';
    position: absolute;
    left: 38px;
    /* top: 121px; */
    bottom: -42px;
    border: 10px solid transparent;
    border-top: 32px solid rgba(0, 0, 0, 0.8);
}
.tultipComponentItems {
    display: flex;
}
.tultipComponentItems > div {
    padding: 5px;
}
.tultipComponentItems > div > img {
    margin: 0px;
}
.tultipComponentItems > div > span {
    width: 100%;
    margin: 0px;
}
.tultipComponentItemsFirst {
    padding: 5px;
}

.radiusPlanPanel * {
    box-sizing: border-box;
    /* font-family: 'Open Sans', sans-serif; */
    font-weight: 100;
    font-size: 13px;
    color: #455a64;
}
