@import './variables.scss';
@import './reset.scss';



.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: $out-of-range-color;
}

.rbc-off-range-bg {
  background: $out-of-range-bg-color;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid $cell-border;

  & + & {
    border-left: 1px solid $cell-border;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  & > a {
    &, &:active, &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
  display: grid;
  grid-template-rows: max-content auto;
  height: 100%;
}

.rbc-today {
  background-color: $today-highlight-bg;
}
.boxLine {
  //margin-top: 18px;
  width: 100%;
  position: relative;
  height: 100%;
  .lineCalendar {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
  }
}

.rbc-day-bg {
  position: relative;
  //.backTultip {
  //  //position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  //z-index: 100000000;
  //  .boxTultip {
  //    display: block;
  //  }
  //}
  .backTultip {
    //position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    background: rgba(47, 57, 73, 0.31);

  }

  .boxTultip {
    width: fit-content;
    height: 182px;
    //position: fixed;
    //right: 119px;
    //top: 178px;
    background: rgba(0, 0, 0, 0.5);
    //z-index: 10;
    display: flex;
    pointer-events: none;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,.5);
    .itemTultip {
      width: 100px;
      height: 100%;
      padding: 2px;
      background: #000;
      img {
        width: 100%;
      }
      .nameTultip {
        display: flex;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 20px;
        align-items: center;
        justify-content: center;
      }
      .time {
        color: #fff;
        font-size: 12px;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }
  }
}
.boxTultip {
  width: 100px;
  height: 100px;
  //position: fixed;
  background: rgba(0,0,0,1);
  z-index: 10;
  display: grid;
  pointer-events: none;
}



@import './toolbar.scss';
@import './event.scss';
@import './month.scss';
@import './agenda.scss';
@import './time-grid.scss';

