$overlayColor: rgba(0, 0, 0, 0.5);

.boxCalendar_overlay,
.boxReportingObjects_overlay,
.boxPanelPL,
.boxRightPanel_overlay {
    -webkit-animation: fade-in 0.15s linear; /* Safari 4+ */
    -moz-animation: fade-in 0.15s linear; /* Fx 5+ */
    -o-animation: fade-in 0.15s linear; /* Opera 12+ */
    animation: fade-in 0.15s linear; /* IE 10+, Fx 29+ */
}
