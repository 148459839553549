.ShowTablesButton {
    background: url('../img/list.svg') no-repeat center;
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-size: 80% 80%;
    border: 1px solid #fff;
    cursor: pointer;
    margin-left: 15px;

    &:active {
        background-size: 60% 60%;
    }
}

.ShowTablesButton-chart {
    background: url('../img/line-chart.svg') no-repeat center;
    background-size: 80% 80%;
}

.CompleteTablesPanel {
    width: 100%;
    height: 100%;
    // height: calc(100% - 50px);
    // background-color: rgb(250, 250, 250);
    display: flex;
    z-index: 300;
    position: absolute;
    left: 0;
    top: 0;
    // overflow-x: scroll;
    // overflow-y: scroll;
}

.TreePanel {
    position: absolute;
    top: 50px;
    left: 0;
    width: 238px;
    height: calc(100% - 50px);
    // height: 100%;
    background-color: rgb(233, 233, 233);
    padding: 10px;
    flex-shrink: 0;
    box-sizing: border-box;
    // overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .TreePanel-container {
        overflow: auto;
        height: calc(100% - 50px);
    }

    form {
        // margin-bottom: 50px;
    }

    .TreePanel_group {
        margin-top: 15px;
    }

    .TreePanel_group-title {
        font-size: 15px;
        margin: 0;
        padding: 0;
        display: flex;
        padding-bottom: 5px;
        border-bottom: 1px solid #8c8c8c;
    }

    .TreePanel_groups-list,
    .TreePanel_tables-list {
        list-style: none;
        margin: 0;
        margin-top: 10px;
        padding: 0;
    }
    .TreePanel_groups-list {
        // padding: 10px;
    }
    // .TreePanel_tables-list {
    //   padding-left: 10px;
    // }
    .TreePanel_table-name {
        display: flex;
        align-items: center;
        // margin-bottom: 10px;
        label {
            cursor: pointer;
            font-family: 'ProximaNova';
            font-size: 14px;
            box-sizing: border-box;
            margin: 0;
            padding: 5px 0;
            display: inline-block;
            vertical-align: middle;
        }
        input {
            cursor: pointer;
            margin-right: 5px;
        }
    }
}

.TabsContainer {
    // position: absolute;
    // top: -50px;
    margin-left: 238px;
    background-color: rgb(250, 250, 250);
    padding: 20px;
    padding-top: 14px;
    width: 100%;
    overflow: scroll;
}

.CT_tab-list {
    margin-top: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.CT_tabs {
  padding-top: 10px;
    // margin-bottom: 50px;
}

.CT_tab-list-item {
    display: inline-block;
    color: rgb(147, 147, 147);
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    &:hover {
        color: rgb(0, 0, 0);
    }
}

.CT_tab-list-active {
    background-color: rgb(250, 250, 250);
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    color: rgb(0, 0, 0);
}

.CT_tab-content {
    width: 100%;
    box-sizing: border-box;
    // display: flex;
    // flex-direction: column;
}

.TableContainer {
    box-sizing: border-box;
    margin-bottom: 20px;
}

.wtHider {
    border-bottom: 1px solid rgb(204, 204, 204);
}

.CompleteTableSaveButton {
    font-family: 'ProximaNova';
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;
    flex-shrink: 0;
    margin-top: 20px;
}

.CT_Modes {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    list-style: none;
    display: flex;
    flex-direction: column;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label {
            cursor: pointer;
            font-family: 'ProximaNova';
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }
        input {
            cursor: pointer;
            margin-right: 10px;
        }
    }
}
