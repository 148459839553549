.extend-region {
	background: rgba(0,0,0,0);
/*    background: green;
    opacity: 0.5;*/
    /*position: absolute;*/
    z-index: 10000;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.main-region {
    /*background: red;*/
    top: 0px;
    left: 0px;
    min-width: 980px;
    height: 100%;
    /*margin-left: 235px;*/
    /*margin-top: 50px;*/
    pointer-events: auto;
}
.boxSpinerLoaderMaps {
    position: fixed;
    top: 50px;
    background: rgba(255,255,255,.5);
    width: 100%;
    height: 100%;
    z-index: 1;
    bottom: 100px;
    left: 0px;
}
input[type=checkbox].inputShowBackground {
    float: left;
    /*margin-left: 295px;*/
    margin-top: 19px;
    margin-right: 5px;
}
label {
    user-select: none;
}
.boxNotCorrect {
    position: fixed;
    top: 99px;
    left: 236px;
    right: 0px;
    bottom: 0px;
    background: #ffffff;
    z-index: 1;
}
.boxNotCorrect > span {
    display: inline-block;
    margin-left: calc((100% - 200px)/2);
    margin-top: 15%;
    color: rgb(197,197,197);
}

.boxMapsZone.openPanel {
    display: grid;
    grid-template-columns: auto 272px;
    grid-template-rows: 100%;

}
.boxMapsZone {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: 100%;
    height: 100%;
}