.SelectInputParameter {
    width: 100%;
    padding-left: 10px;
    position: relative;
    select {
        width: 100%;
        margin-bottom: 10px;
        outline: none;
        border: none;
        font-family: ProximaNova;
        font-size: 10pt;
        color: rgb(130, 127, 125);
    }
}

.SelectInputParameter__title {
    font-family: ProximaNova;
    font-size: 11pt;
    font-style: italic;
    margin-bottom: 5px;
    display: inline-block;
    color: rgb(130, 127, 125);
  }
  
  .SelectInputParameter__styled {
    //background: url(http://i62.tinypic.com/15xvbd5.png) no-repeat 96% 0;
    height: 29px;
    overflow: hidden;
    width: 240px;
  }
  
  .SelectInputParameter__styled select {
    color: rgb(130, 127, 125);
    background: transparent;
    border: none;
    font-size: 14px;
    height: 29px;
    padding: 0 5px; /* If you add too much padding here, the options won't show in IE */
    width: 268px;
  }
  
  .SelectInputParameter__styled.slate {
    //background: url(http://i62.tinypic.com/2e3ybe1.jpg) no-repeat right center;
    height: 23px;
    width: 218px;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
  }
  
  .SelectInputParameter__styled.slate select {
    color: rgb(130, 127, 125);
    //border: 1px solid rgb(220, 220, 220);
    font-size: 13px;
    height: 23px;
    width: 268px;
}

.SelectInputParameter__arrow {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 25px;
    width: 25px;
    text-align: center;
    /* padding-bottom: 3px; */
    box-sizing: border-box;
    //border-radius: 5px;
    background: #eef0f0;
    padding-top: 2px;
    pointer-events: none;
    i {
        height: 25px;
        display: inline-block;
        font-size: 20px;
        color: rgb(36, 41, 45);
    }
}
