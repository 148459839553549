$heightButtonsLeftPanel: 92px;
$bacgroundButtonActive: rgb(225,227,225);
$backgroundDividerVertical: #45b874;

.buttonsList {
  width: 100%;
  //padding-left: 8px;
  box-sizing: border-box;
  // padding-top: 50px;
  border-bottom: 1px solid rgb(196,193,191);
  // .buttonLeftPanel {
    // cursor: pointer;
    // width: 100%;
    // height: fit-content;
    // //text-align: center;
    // //text-transform: uppercase;
    // display: grid;
    // grid-template-columns: 8px auto;
    // grid-template-rows: 100%;
    // //border-top: 1px solid rgb(243,243,243);
    // border-bottom: 1px solid rgb(243,243,243);
    // .dividerVertical {
    //   width: 7px;
    //   height: 100%;
    //   float: left;
    // }
    // .textBox {
    //   padding-left: 10px;
    //   padding-top: 8px;
    //   span {
    //     float: left;
    //     padding-top: 0px;
    //     font-size: 17px;
    //     font-family: ProximaNova;
    //     padding-bottom: 6px;
    //     color: #827f7d;
    //   }
    //   i {
    //     float: left;
    //     margin-right: 14px;
    //     color: rgba(62,57,53,1);
    //   }
    //   i.fa-signal {
    //     padding-top: 9px;
    //   }
    //   i.fa-mobile {
    //     font-size: 1.6em;
    //     margin-top: 2px;
    //   }
    //   i.fa-flask {
    //     font-size: 15pt;
    //     margin-top: 5px;
    //     margin-left: -4px;
    //   }
    //   i.fa-cog {
    //     margin-right: 0px;
    //     margin-left: 15px;
    //     margin-top: 8px;
    //   }
    // }
  // }
  // .buttonLeftPanel:hover {
  //   //color: rgba(65, 162, 104, 0.6);

  //   .textBox {
  //     //padding-left: 20px;
  //     span {
  //       color: rgba(65, 162, 104, 0.6);
  //     }

  //   }
  // }

  // .buttonLeftPanel.active {
  //   width: 100%;
  //   height: fit-content;

  //   //text-align: center;
  //   background: $bacgroundButtonActive;
  //   //text-transform: uppercase;
  //   .dividerVertical {
  //     width: 7px;
  //     height: 100%;
  //     float: left;
  //     background: $backgroundDividerVertical;
  //   }
  //   .textBox {
  //     padding-left: 20px;
  //     span {
  //       color: #3da066;
  //     }

  //   }
  // }
}
