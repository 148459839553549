$axes-color: #777;

.boxDiagram {
    //width: 300px;
    //height: 300px;
    ////background: #8c9790;
    .Diagram {
        width: 200px;
        height: 200px;
        //background: #ffffff;
        //padding: 5px;
        //border: 4px solid green;
        //outline: 1px solid red;
        display: inline-block;
        position: relative;
        float: left;
        padding: 8px;
        box-sizing: border-box;
        .contentDataView {
            width: 100%;
            height: 100%;
            background: #ffffff;
            float: left;
            padding: 8px;
            box-sizing: border-box;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        }
        .contentDataViewFlex {
            width: 100%;
            height: 100%;
            background: #ffffff;
            display: flex;
            flex-direction: column;
            // float: left;
            padding: 8px;
            box-sizing: border-box;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        }
        .spinerBox {
            width: 40px;
            height: 38px;
            margin: auto;
            //margin-top: calc((100% - 38px)/2);
        }
        .buttonShowDetail {
            background: transparent;
            position: absolute;
            top: 9px;
            right: 4px;
            width: 21px;
            height: 21px;
            padding: 0px;
            outline: none;
            border: none;
            padding-top: 5px;
            cursor: pointer;
            .buttonPanelShow {
                display: none;
                width: 20px;
                height: 20px;
                background: url(https://png.icons8.com/color/50/000000/menu-2.png) no-repeat center;
                background-size: 16px;
            }
        }
    }
    .Diagram:hover {
        width: 200px;
        height: 200px;
        //background: #ffffff;
        //padding: 5px;
        //border: 4px solid green;
        //outline: 1px solid red;
        display: inline-block;
        position: relative;
        float: left;
        padding: 8px;
        box-sizing: border-box;
        .contentDataView {
            width: 100%;
            height: 100%;
            background: #ffffff;
            float: left;
            padding: 8px;
            box-sizing: border-box;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        }

        .contentDataViewFlex {
            width: 100%;
            height: 100%;
            background: #ffffff;
            display: flex;
            flex-direction: column;
            // float: left;
            padding: 8px;
            box-sizing: border-box;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        }
        .spinerBox {
            width: 40px;
            height: 38px;
            margin: auto;
            //margin-top: calc((100% - 38px)/2);
        }
        .buttonShowDetail {
            // background: rgba(0, 0, 0, 0.5);
            // position: absolute;
            // top: 9px;
            // right: 4px;
            // width: 21px;
            // height: 21px;
            // padding: 0px;
            // outline: none;
            // border: none;
            // padding-top: 5px;
            // cursor: pointer;
            .buttonPanelShow {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url(https://png.icons8.com/color/50/000000/menu-2.png) no-repeat center;
                background-size: 16px;
            }
        }
    }
    //.buttonShowDetail {
    //  background: rgba(0, 0, 0, 0);
    //  position: absolute;
    //  top: 9px;
    //  right: 4px;
    //  width: 21px;
    //  padding: 0px;
    //  outline: none;
    //  border: none;
    //  padding-top: 5px;
    //  cursor: pointer;
    //  .buttonPanelShow {
    //    width: 20px;
    //    height: 20px;
    //    background: url(https://png.icons8.com/color/50/000000/menu-2.png) no-repeat center;
    //    background-size: 16px;
    //  }
    //}
    .buttonShowDetailLog {
        position: absolute;
        bottom: 8px;
        right: 8px;
        cursor: default;
        width: 5px;
        height: 5px;
        color: grey;
        font-size: 13px;
    }
}
pre {
    width: 200px;
    height: 200px;
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
    overflow: auto;
}
.modalPanelDetail {
    position: fixed;
    top: 0px;
    left: 238px;
    right: 0px;
    bottom: 0px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.5);
    z-index: 201;
    padding: 10px;
    -webkit-user-select: text;

    overflow: auto;
    /* user-select -- это нестандартное свойство */

    -moz-user-select: text;
    /* поэтому нужны префиксы */

    -ms-user-select: text;
    pre {
        width: 100%;
        height: fit-content;
        display: block;
        font-family: monospace;
        white-space: pre;
        margin: 1em 0;
        overflow: auto;
        color: #fff;
    }
    button {
        position: absolute;
        top: 0px;
        right: 0px;
    }
}

.DataViewScore {
    span {
        color: #646464;
        font-family: 'ProximaNova';
        font-size: 23pt;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 24px 0;
    }
}
.DataViewComparisonScoreDiagram {
    span.mainSpan {
        color: #646464;
        font-family: 'ProximaNova';
        font-size: 16pt;
        display: inline-block;
        width: 50%;
        text-align: right;
        margin: 10px 0;
        padding-right: 10px;
        box-sizing: border-box;
    }
    span.compareSpan {
        color: #646464;
        font-family: 'ProximaNova';
        font-size: 16pt;
        display: inline-block;
        width: 50%;
        //text-align: center;
        margin: 10px 0;
        padding-left: 10px;
        box-sizing: border-box;
        float: right;
    }
}
.containerSpiner {
    display: table;
    height: calc(100% - 33px);
    position: absolute;
    overflow: hidden;
    width: calc(100% - 33px);
}
.helper {
    position: absolute;
    top: calc((100% - 42px) / 2);
    left: calc((100% - 42px) / 2);
    display: table-cell;
    vertical-align: middle;
}
.content {
    position: relative;
    top: -50%;
    margin: 0 auto;
    //width: 200px;
    //border: 1px solid orange;
}
.rowDataView {
    width: 100%;
    height: 34px;
    .parentTitle {
        height: 30px;
        display: inline-block;
        position: relative;
        text-align: center;
        width: 100%;
        #floater {
            height: 100%;
            width: 0px;
            display: inline-block;
            vertical-align: middle;
        }
        .childTitle {
            vertical-align: middle;
            display: inline-block;
            color: #6a7074;
            h4 {
                font-family: 'ProximaNova';
                text-align: center;
                color: #6a7074;
                margin: 0px;
                font-size: 14px;
            }
        }
    }
    h4 {
        font-family: 'ProximaNova';
        text-align: center;
        color: rgb(36, 59, 56);
    }
    .itemPersent {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin: 13px 0;
        .persent {
            font-family: 'ProximaNova';
            font-size: 16pt;
            color: rgb(36, 49, 56);
            margin-left: 10px;
        }
    }
}

.regionX {
    fill: #ff7a77 !important;
}

.newLine {
    width: 100%;
    float: left;
    //opacity: 1;
    h2 {
        font-family: ProximaNova;
        font-size: 17px;
        color: #243138;
        margin: 16px 8px;
        margin-bottom: 0px;
    }
    .HorizonDivader {
        width: 100%;
        height: 2px;
        box-sizing: border-box;
        /* padding-left: 8px; */
        /* padding-right: 8px; */
        padding: 8px;
        .divader {
            width: 100%;
            height: 1px;
            background: #99ccae;
        }
    }
}
.iconBoxDataView {
    span {
        color: grey;
        font-size: 12pt;
        margin-bottom: 10px;
        margin-top: 10px;
        font-family: 'ProximaNova';
        font-style: oblique;
        color: rgb(161, 165, 167);
    }
    margin: 0 auto;
    width: 168px;
    height: 25px;
    position: relative;
    top: calc(50% - 56px);
    i {
        display: inline-block;
        margin-left: 73px;
        color: rgb(161, 165, 167);
    }
}

.dataCountPdf {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

// .c3 {
//   background-color: #4F5E63;
// }
.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line {
    stroke: $axes-color;
}

.c3 .c3-axis-x g,
.c3 .c3-axis-y g,
.c3 .c3-legend-item-data text,
.c3 .c3-axis-y-label,
.c3 .c3-axis-x-label,
.c3 .c3-legend-item {
    fill: $axes-color;
}

.c3-ygrid-line.main_date_range line {
    stroke: rgb(103, 148, 208);
}

.c3-ygrid-line.main_date_range text {
    fill: rgb(103, 148, 208);
}

.c3-ygrid-line.comparison_date_range line {
    stroke: rgb(255, 191, 88);
}

.c3-ygrid-line.comparison_date_range text {
    fill: rgb(255, 191, 88);
}

/*when displaying the data on the chart */
// .c3 text.c3-text {
//   fill: whitesmoke!important;
//   stroke: whitesmoke!important;
//   stroke-opacity: 0.3!important;
// }

/* for the tooltip text color */
// .c3 .c3-tooltip-container {
//   color:darkgrey;
// }
