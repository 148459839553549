.pdfBox {
  width: 100%;
  //height: 100px;
  //outline: 1px solid #0b2e13;
  .bodyPanelPdf {
    height: 100%;
    width: 100%;
    margin-top: 20px;
    .itmeRow {
      width: 100%;
      height: fit-content;
      box-sizing: border-box;
      padding-left: 20px;

      .itmeRowCount {
        width: 335px;
        .leftItemRow {
          float: left;
          width: calc(100% / 2);
          .itemRowChiled {
            margin-left: 10px;
            float: left;
            font-family: 'ProximaNova';
            text-align: center;
            color: #6a7074;
            margin: 0px;
            font-size: 14px;
          }
        }
        .rightItemRow {
          float: left;
          width: calc(100% / 2);
          .itemRowChiled {
            margin-left: 10px;
            float: left;
            font-family: 'ProximaNova';
            text-align: center;
            color: #6a7074;
            margin: 0px;
            font-size: 14px;
          }
        }
      }


    }

    .itmeRow.locations {


      min-width: 100px;
      display: inline-block;
      text-align: left;
      vertical-align: top;
      margin: 4px 0px;
      box-sizing: border-box;
      padding-left: 20px;
      width: 790px;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;

      .itmeRowCount {
        width: fit-content;
        //height: 18px;
        .leftItemRow {
          float: left;
          width: calc(100% / 2);
          .itemRowChiled {
            margin-left: 10px;
            float: left;
            font-family: 'ProximaNova';
            text-align: center;
            color: #6a7074;
            margin: 0px;
            font-size: 14px;
          }
        }
        .rightItemRow {
          float: left;
          width: calc(100% / 2);
          .itemRowChiled {
            margin-left: 10px;
            float: left;
            font-family: 'ProximaNova';
            text-align: left;
            color: #6a7074;
            margin: 0px;
            font-size: 14px;
          }
        }
      }


    }

  }
}
.rightItemRow.fullRow {
  width: 100% !important;
}
.bodyPanelPdfitem {
  h2 {
    font-family: ProximaNova;
    font-size: 17px;
    color: #243138;
    margin: 5px 10px;
  }
}
.padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
