$funnelTitle: #cecece;

.FunnelSet {
  height: auto !important;
}

.SetContainer .DataViewFunnelContainer:first-of-type .FunnelTextDataList_title {
  margin-top: 0;
  padding-top: 0px;
}

.MoreObjects {
  margin-top: 20px ;
  text-align: right;
}

.DataViewFunnelContainer {
  position: relative;
  // width: 100px;
  // height: 272px;
  height: auto;
  // background-color: red;
  .FunnelTextDataList_title {
    font-family: 'ProximaNova';
    font-size: 16px;
    color: #6b7074;
    margin: 10px 0;
    padding: 10px 0;
    padding-top: 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid $funnelTitle;
    // border-top: 1px solid @funnelTitle;
  }


  .FunnelSVG {
    width: 100%;

    // background-color: rgb(213, 213, 213);
    // border: 1px solid rgb(211, 187, 187);
  }
  .FunnelSetSvg {
    padding-bottom: 10px;
    // border-bottom: 1px solid @funnelTitle;
  }
  .FunnelTextDataList {
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    // background-color: rgba(235, 18, 18, 0.295);

    .FunnelTextDataItem {
      font-family: 'ProximaNova';
      font-size: 18px;
      list-style: none;
      margin: 0;
      padding: 0;
      // background-color: rgba(18, 235, 105, 0.295);
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: column;
      margin: 0 15px;

      .LayerHeader {
        display: flex;
        align-items: stretch;
        flex-direction: column;

        .LayerTitle {
          font-size: 16px;
          color: #6b7074;
          margin: 0;
          padding: 0;
          display: flex;
          align-self: center;
          margin-bottom: 10px;
          font-weight: 400;
        }

        .LayerTopData {
          display: flex;
          justify-content: space-between;
        }

        p {
          margin: 0;
          padding: 0;
        }

        .LayerTopData_percent_point {
          color: rgb(166, 170, 180);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30%;
          flex-grow: 1;
        }

        .LayerTopData_input_percent {
          color: rgb(60, 100, 153);
          display: flex;
          align-items: center;
          width: 30%;
          flex-grow: 1;
        }

        .LayerTopData_output_percent {
          color: rgb(60, 100, 153);
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 30%;
          flex-grow: 1;
        }
      }

      .LayerFooter {
        display: flex;
        align-items: stretch;
        flex-direction: column;

        .LayerBottomData {
          display: flex;
          justify-content: space-between;

          .LayerBottomData_pair_compare_container {
            display: flex;
            align-items: center;
            position: relative;

            i {
              display: block;
              position: absolute;
              top: 1px;
            }
          }

          .LayerBottomData_pair_compare {
            padding-left: 20px;
          }

          .LayerBottomData_data {
            color: rgb(60, 100, 153);
            width: 30%;
          }

          .LayerBottomData_data:last-child {
            display: flex;
            justify-content: flex-end;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

// .FunnelPrime {
//   fill: url(#funnel-gradient);
//   stroke-width: 0px;
//   // color: rgb(255, 128, 128);
// }

// .FunnelBack {
//   fill: url(#red-gradient);
//   stroke-width: 0px;
// }
