.boxMapsHolst {
    padding: 12px;
    //padding-bottom: 55px;
    width: 100%;
    height: calc(100% - 51px);
    overflow: auto;
    box-sizing: border-box;
    .holstBlock {
        //width: fit-content;
        display: grid;
        //grid-template-columns: 100%;
        //grid-template-rows: auto;
        justify-items: center;
        .itemMaps {
            margin-top: 30px;
            margin-bottom: 30px;
            margin: 5px;
            box-sizing: border-box;
        }
    }
}

.boxButtonScale {
    position: fixed;
    bottom: 100px;
    left: 251px;
    border-radius: 5px;
    padding: 0px 4px;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
    div {
        text-align: center;
        font-size: 30px;
        width: 28px;
        height: 32px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #ffffff;
        cursor: pointer;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        align-items: center;
    }
}
.boxVersionsAndTitle {
    position: fixed;
    right: 15px;
    width: 350px;
    bottom: 12px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    span {

    }
    .boxVersions {

        width: 100%;

    }
}
.boxMap {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    justify-items: center;
    width: 100%;
    height: fit-content;
}

.boxDataViewInfo {

    left: 546px;
    /* width: 220px; */
    bottom: 12px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    justify-items: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    margin-left: 0px;
    margin-right: 13px;
    margin-top: 13px;
    float: left;
    .dataViewInfo {

        width: 100%;

    }
}
.boxTitleMetric {
    left: 487px;
    width: fit-content;
    bottom: 17px;
    padding-right: 5px;
}

.inputShowBackgroundLabel {
    float: left;
    margin-top: 16px;
}
.boxRainbow {
    position: fixed;
    bottom: 51px;
    left: 241px;
    /* width: calc(100% - 588px); */
    height: 35px;
    background: rgba(255, 255, 255, 0.5);
    right: 298px;
}
