$backgroundGlobalStyle: rgba(70, 184, 117, 1);
$widthTopPanel: 100%;
$heightTopPanel: 50px;
$widthLeftPanel: 238px;
.topPanel {
    // background: $backgroundGlobalStyle;
    width: $widthTopPanel;
    height: $heightTopPanel;

    display: flex;
    align-items: center;
}

.boxCalendarRange {
    display: flex;
    width: auto;
    height: 100%;
    flex-shrink: 0;
}
.itemCalendar {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #827f7d;
    justify-content: center;
    span {
        display: block;
        flex-shrink: 0;
        color: #827f7d;
    }
}
.boxButtonForCalendar {
    display: flex;
    // flex-wrap: wrap;
    height: 100%;
    font-size: 10pt;
    align-items: center;
    .boxButtonForCalendarItem {
        cursor: pointer;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 80%;
        .boxButtonLeft {
            width: 202px;
            display: grid;
            grid-template-columns: 4px auto;
            grid-template-rows: 100%;
            // height: 30px;
            background: rgba(255, 255, 255, 0.8);
            // width: 93%;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
            .dividerCalendarLeft {
                background: #f49b10;
            }
        }
        .boxButtonRight {
            margin-left: 15px;
            margin-right: 5px;
            width: 202px;
            display: grid;
            grid-template-columns: 4px auto;
            grid-template-rows: 100%;
            // height: 30px;
            background: rgba(255, 255, 255, 0.8);
            // width: 93%;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
            .dividerCalendarRight {
                background: #3c6499;
            }
        }
        .boxButtonLeft:hover {
            background: rgba(255, 255, 255, 1);
        }
        .boxButtonRight:hover {
            background: rgba(255, 255, 255, 1);
        }
        .itemCalendarDivaderCompare {
            display: grid;
            justify-items: center;
            align-items: center;
            .divider {
                width: 63%;
                background: #f49b10;
                height: 100%;
            }
        }
        .itemCalendarDivaderMain {
            display: grid;
            justify-items: center;
            align-items: center;
            .divider {
                width: 63%;
                background: #3c6499;
                height: 100%;
            }
        }
    }
}

.boxCalendarLib {
    position: fixed;
    z-index: 10000;
    background: #ffffff;
    height: 447px;
    width: 1010px;
    right: 200px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 264px auto;
    grid-template-rows: 100%;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.18);
    .Apply {
        position: absolute;
        right: 12px;
        bottom: 12px;
        padding: 5px;
        border-radius: 3px;
        background: #3c6499;
        color: #fff;
        z-index: 1000000;
    }

    .tempBox {
        height: 296px;
        width: 264px;
        box-sizing: border-box;
        padding: 10px;
        z-index: 10000;
        .boxButton {
            width: 100%;
            height: 100%;
            //border-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            display: grid;
            grid-template-columns: 100%;
            justify-content: center;
            justify-items: center;
            align-items: center;
            padding: 8px;
            box-sizing: border-box;
            .headerButton {
                display: grid;
                grid-template-columns: auto auto;
                grid-template-rows: 100%;
                width: 94%;
                justify-items: center;
                button {
                    width: 92%;
                    //height: 44px;
                    padding: 7px;
                    background: #fff;
                    border-radius: 5px;
                    font-size: 10pt;
                    font-weight: 100;
                    color: #586267;
                    border: 1px solid #a9a8a8;
                    cursor: pointer;
                }
            }
            .disabledButton {
                opacity: 0.5;
                cursor: default;
            }
            button {
                width: 90%;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                padding: 7px;
                background: #fff;
                border-radius: 5px;
                font-size: 10pt;
                font-weight: 100;
                color: #586267;
                border: 1px solid #a9a8a8;
                cursor: pointer;
            }
        }
    }
}

.boxCalendarLib.left {
    position: fixed;
    top: 110px;
    left: 253px;
    .backgroundModalDateRange {
        position: fixed;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        z-index: -1;
    }
    .buttonBox {
        position: absolute;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 25px;
        width: 244px;
        top: 8px;
        left: 10px;
        height: 55px;
        justify-items: center;
        button {
            width: 100%;
            height: 44px;

            padding: 7px;
            background: #fff;
            /* border-radius: 5px; */
            font-size: 10pt;
            font-weight: 100;
            color: #586267;
            border: none;
            cursor: pointer;
        }
        .left {
            color: #f49b10;
            background: #fcdfb7;
            border-top-left-radius: 5px;
            outline: none;
            border-left: 1px solid #fcdfb7;
            border-top: 1px solid #fcdfb7;
        }
        .right {
            color: rgb(129, 144, 149);
            /* background: #fcdfb7; */
            border-top-right-radius: 5px;
            border-right: 1px solid #fcdfb7;
            border-top: 1px solid #fcdfb7;
            outline: none;
        }
    }
    .tempBox {
        margin-top: 42px;

        .boxButton {
            background: #fcdfb7;
            grid-template-rows: auto auto auto auto auto;
        }
    }
}

.boxCalendarLib.right {
    position: fixed;
    top: 110px;
    left: 253px;
    .backgroundModalDateRange {
        position: fixed;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        z-index: -1;
    }
    .buttonBox {
        position: absolute;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 25px;
        width: 244px;
        top: 8px;
        left: 10px;
        height: fit-content;
        justify-items: center;
        button {
            width: 100%;
            height: 44px;

            padding: 7px;
            background: #fff;
            /* border-radius: 5px; */
            font-size: 10pt;
            font-weight: 100;
            color: #586267;
            border: none;
            cursor: pointer;
        }
        .left {
            color: rgb(129, 144, 149);
            /* background: #fcdfb7; */
            border-top-left-radius: 5px;
            border-left: 1px solid #bfccde;
            border-top: 1px solid #bfccde;
            outline: none;
        }
        .right {
            color: #3c6499;
            //color: #fff;
            background: #bfccde;
            border-top-right-radius: 5px;
            outline: none;
            border-right: 1px solid #bfccde;
            border-top: 1px solid #bfccde;
        }
    }
    .tempBox {
        margin-top: 42px;
        .boxButton {
            background: #bfccde;
            grid-template-rows: auto auto auto auto auto auto;
        }
    }
}

.rightSide {
    //margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    // padding: 0 20px;
}
.buttonClosePdfType {
    float: right;
}
.rdrCalendarWrapper {
    z-index: 100000;
}
.rdrDay {
    width: 14.28% !important;
}
